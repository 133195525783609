@charset "utf-8";
$assets_path: "https://common.jobplanet.co.kr/images";
$assets_font_path: "https://common.jobplanet.co.kr/fonts";
body,
select,
input,
textarea,
svg {
  font-family: "Roboto", "Noto Sans Korean", sans-serif !important;
}


@import "./jply_common/_common.scss";
@import "./partners/b2b_navi.scss";