/* jf_input 2022.12.27 */
.jf_input{
  $this:&;
  position: relative;
  &.is_disabled{
    #{$this}__input{
      background: $gray30;
      color: $gray100;
      border-color: $gray100;
      cursor: not-allowed;
    }
  }
  &.is_error{
    #{$this}__error{
      margin-top: 2px;
      position: absolute;
      padding-right: 32px;
      box-sizing: border-box;
    }
    #{$this}__current{
      color: $red500;
    }
    #{$this}__input{
      border-color: $red500;
    }
  }
  &__label{
    position: relative;
    margin-bottom: 2px;
    color: $gray800;
    &.is-required{
      &:after{
        position: absolute;
        top: -4px;
        bottom: 0;
        left: 100%;
        content: "*";
        display: block;
        color: $green500;
        padding-left: 2px;
      }
    }
  }
  &__error{
    margin: 0;
    color: $red500;
  }
  &__close{
    position: absolute;
    top: 14px;
    right: 12px;
    padding: 0;
    box-sizing: border-box;
    border:none;
    background: transparent;
    color: $gray200;
    cursor: pointer;
  }
  &__outer{
    display: block;
  }
  &__inner{
    position: relative;
    display: inline-block;
    min-width: 286px;
    font-size: 0;
    &:after{
      display: block;
      clear:both;
      content: '';
    }
  }
  &__input{
    height: 48px;
    width: 100%;
    line-height: 48px;
    padding: 0 36px 0 20px;
    border: 1px solid $gray100;
    box-sizing: border-box;
    border-radius: 8px;
    color: $gray800;
    &:hover{
      border-color:$gray200;
      &::placeholder{
        color:$gray800;
      }
    }
    &:focus{
      outline: none;
      border-color:$green500;
    }
    &::placeholder{
      color:$gray200;
    }
  }
  &__textarea{
    min-height: 110px;
    width: 100%;
    padding: 12px 36px 12px 20px;
    border: 1px solid $gray100;
    box-sizing: border-box;
    border-radius: 8px;
    color: $gray500;
    resize : vertical;
    &:hover{
      border-color:$gray200;
      &::placeholder{
        color:$gray500;
      }
    }
    &:focus{
      outline: none;
      border-color:$green500;
    }
    &::placeholder{
      color:$gray200;
    }
    &:-ms-input-placeholder {
      color: $gray200 !important;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0.5);
      border-radius: 4px;
      background-clip: padding-box;
      border: 1px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
    }
  }
  &__length{
    margin-top: 2px;
    text-align: right;
    color: $gray200;
  }
  &__current{
    color: $green500;
  }
}

/* jf_select 2023.02.20 */
.jf_select{
  $this:&;
  position: relative;
  min-width: 28px;
  display: inline-block;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  border: none;
  &.is_disabled{
    #{$this}__btn{
      background: $gray30;
      color: $gray100;
      border-color: $gray100;
      cursor: not-allowed;
    }
    &:hover{
      #{$this}__btn{
        border-color: $gray100;
      }
    }
  }
  &.is_on{
    #{$this}__btn{
      background: $gray30;
    }
    &:hover{
      #{$this}__btn{
        border-color: $gray100;
      }
    }
  }
  &.is_active{
    #{$this}__btn{
      color: $green500;
      background: $green30;
      border: 1px solid $green200;
    }
    #{$this}__icon{
      color: $green500;
    }
    &:hover{
      #{$this}__btn{
        border-color: $green200;
      }
    }
  }

  &:hover{
    #{$this}__btn{
      border-color: $gray200;
    }
  }
  &.size_large{
    #{$this}__btn{
      height: 48px;
      line-height: 48px;
      border-radius: 8px;
    }
  }
  &.size_medium{
    #{$this}__btn{
      height: 40px;
      line-height: 40px;
      border-radius: 6px;
    }
  }
  &.size_small{
    #{$this}__btn{
      height: 28px;
      line-height: 28px;
      border-radius: 4px;
    }
  }
  &.size_ghost{
    #{$this}__btn{
      border: 0px;
      height: 28px;
      line-height: 28px;
      border-radius: 4px;
    }
    &.is_active{
      #{$this}__btn{
        background: transparent;
      }
    }
    &.is_disabled{
      #{$this}__btn{
        background: transparent;
      }
    }
  }
  &__select{
    position: absolute;
    box-sizing: content-box;
    top: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    border: none;
    cursor: pointer;
    vertical-align: middle;
    z-index: 1;
    -webkit-appearance: none;
    &:focus {
      outline: 0;
    }
  }
  &__btn{
    position: relative;
    overflow: hidden;
    padding: 0 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    outline: none;
    border: 1px solid $gray100;
    background-color: #fff;
    text-align: left;
    width: 100%;
    color: $gray800;
  }
  &__icon{
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: $gray200;
  }
}

/* jf_checkbox 2023.02.20 */
.jf_checkbox {
  $this:&;
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  min-height: 16px;
  align-items: center;
  padding-left: 0;
  width: max-content;
  cursor: pointer;
  &__input {
    opacity: 0;
    filter: Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0;
    &:disabled {
      &~#{$this}__icon {
        cursor: not-allowed;
        color: $gray100;
      }

      &~#{$this}__text {
        cursor: not-allowed;
        color: $gray200;
      }
    }
    &[readonly] {
      &~#{$this}__icon {
        cursor: not-allowed;
        color: $gray500;
      }
    }
  }

  &__icon {
    position: relative;
    top: -1px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $gray200;
    i.jp-checkbox-checked_fill{
      color: $green500;
    }
  }

  &__text {
    flex: 1;
    margin-left: 8px;
    color: $gray800;
  }
}

/* jf_radio 2023.02.20 */
.jf_radio {
  $this:&;
  display: flex;
  position: relative;
  width: auto;
  height: auto;
  min-height: 16px;
  align-items: center;
  padding-left: 0;
  width: max-content;
  cursor: pointer;

  &__input {
    opacity: 0;
    filter: Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0;
    &:disabled {
      &~#{$this}__icon {
        cursor: not-allowed;
        color: $gray100;
      }

      &~#{$this}__text {
        cursor: not-allowed;
        color: $gray200;
      }
    }
    &[readonly] {
      &~#{$this}__icon {
        cursor: not-allowed;
        color: $gray500;
      }
    }
  }

  &__icon {
    position: relative;
    top: -1px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $gray200;
    i.jp-radio-checked{
      color: $green500;
    }
  }

  &__text {
    flex: 1;
    margin-left: 8px;
    color: $gray800;
  }
}