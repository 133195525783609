.btn-loading { width: 100%; height: 100%; }
.btn-full-width { display: block; width: 100%; }
.no-data { display: none; font-size: 20px; }
.media-hidden { display: none; }
.rating-circle {
	background-color: #428bca;
	width: 30px;
	height: 30px;
	color: #fff;
	border-radius: 50%;
	text-align: center;
	font-size: 1.2em;
	padding-top: 3px;
}

.highcharts-gauge {
	height: 100px;
}
.competitor-title {	padding: 10px 0; margin: 0; background-color: #aab6bd; color: #fff; }
.form-inline .form-control.competitor-select { width: 200px; }
.company-title { padding: 10px 0; margin: 0; background-color: #52626b; color: #fff; }

.competitor-title .close-link { color: #fff; float: right; margin-right: 10px; }
.review-contents { min-height: 50px; max-height: 400px; overflow-y: scroll; }
.review-contents > p { margin-bottom: 10px; }
.review-contents > p:last-child { margin-bottom: 0; }
.review-contents > p > em {
    font-style: normal;
    font-weight: bold;
}
.review-contents > p.pros > em { color: #0f7ccf; }
.review-contents > p.cons > em { color: #fd4a12; }
.review-contents > p.message_to_management > em { color: #444444; }
.ibox-content.review-box { padding: 25px 22px; }
.review-cloud { line-height: 2.0em; }
.review-cloud > a { padding: 0 3px; }
.dash-border-right { border-right: 1px dashed #fff; }
.logo { width: 100px; height: 100px; border: 1px solid #e6e6e6; }
.va_top, .table > thead > tr > th.va_top, .table > tbody > tr > th.va_top, .table > tfoot > tr > th.va_top, .table > thead > tr > td.va_top, .table > tbody > tr > td.va_top, .table > tfoot > tr > td.va_top  { vertical-align: top; }
.va_middle, .table > thead > tr > th.va_middle, .table > tbody > tr > th.va_middle, .table > tfoot > tr > th.va_middle, .table > thead > tr > td.va_middle, .table > tbody > tr > td.va_middle, .table > tfoot > tr > td.va_middle  { vertical-align: middle; }
.va_bottom, .table > thead > tr > th.va_bottom, .table > tbody > tr > th.va_bottom, .table > tfoot > tr > th.va_bottom, .table > thead > tr > td.va_bottom, .table > tbody > tr > td.va_bottom, .table > tfoot > tr > td.va_bottom  { vertical-align: bottom; }
.alert.upgrade_msg { margin-top: 25px; width: 961px; margin-left: 10px; }
.ui-widget-content { display: none; }
.table.table-sm-font { font-size: 11px; }

/* Styles for Insights */
.insight-sample-image {
    width: 100%;
    height: 100%;
}
.insight-sample-image, map area{
    border: 0;
    outline: none;
}
.insight-service-inquiry-div {
    width: 576px;
    height: 186px;
    background: url('../../images/service_inquiry_banner.png') no-repeat 0 0;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -93px;
    margin-left: -288px;
    padding: 35px 50px 20px 190px;
}
.insight-service-inquiry-div h4 {
    font-size: 14px;
    font-weight: lighter;
    color: #2e4f65;
    line-height: 1.5em;
}
.insight-service-inquiry-div a, .insight-service-inquiry-div a:hover, .insight-service-inquiry-div a:focus, .insight-service-inquiry-div a:visited {
    background-color: #299bea;
    border-radius: 2px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    width: 200px;
    height: 40px;
    padding-top: 10px;
    margin-top: 5px;
}

/* Styles for company custom contents */
.note-editor .note-editable { min-height: 400px; }
.gauge-row .ibox-content { padding-top: 0; padding-bottom: 0; }
.custom-content-table > thead > tr > th { text-align: center; }
.ibox .note-editor .dropdown-menu { z-index: 3000; }

/* Styles for HR news */
.feed-activity-list h2 { margin-bottom: 6px; }
.feed-activity-list { margin-top: 20px; }
.feed-activity-list .media-body { font-size: 14px; display: block;  width: auto;}
.feed-activity-list .media-body .desc { font-size: 12px; margin-top: 4px; }
.feed-activity-list .feed-element img.img-circle { width: 78px; height: 78px; }
.feed-activity-list a { color: #0f7ccf; }
.feed-activity-list a:hover { text-decoration: underline; }
.feed-activity-list .feed-element { /*border-bottom: 0 !important; padding-bottom: 6px;*/   border-bottom: 1px solid #e7eaec; }
.feed-activity-list .feed-element.feed-sidebar { padding-bottom: 0px; }

/* Styles for custom link */
.link, .link:hover {
    text-decoration: underline;
    color: #999;
}
.link-default, .link-default:hover { color: #999; }
.link-danger, .link-danger:hover { color: #fd4a12; }
.link-success, .link-success:hover { color: #0f7ccf; }

/* Styles for HR Lab. */
.product-imitation { background-size: 100% !important; }
.premium-report-description { height: 45px; overflow: hidden; }

/* 호상님 요청 추가 */
.field_with_errors { display: inline; }
.ui-helper-hidden-accessible { display: none; }
.has-error .error-msg { color: #ED5565 ; margin: 5px; }

/* summernote */
.mac{
    font[face='나눔고딕'] {
        font-family: '나눔고딕', NanumGothic, 'Apple SD Gothic Neo', sans-serif;
    }
    font[face='궁서'] {
        font-family: 'GungSeo', 'Serif';
    }
}
.win{
    font[face='나눔고딕'] {
        font-family: '나눔고딕', NanumGothic,'맑은 고딕','Malgun Gothic', '돋움', dotum, Tahoma, Verdana, Arial, sans-serif;
    }
    font[face='궁서'] {
        font-family: '궁서', '바탕체', Serif;
    }
}
