/* Reset */
@charset "utf-8";
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button {margin:0;padding:0}
fieldset,img {border:0 none}
dl,ul,ol,menu,li {list-style:none}
blockquote, q {quotes: none}
blockquote:before, blockquote:after,q:before, q:after {content:'';content:none}
button {border:0 none;background-color:transparent;cursor:pointer}
body,h1,h2,h3,h4,th,td,input,select,textarea,button {font-size:13px;color:#333}
h3, h4, h5 {margin-top: 5px;font-weight: 600;}
a {color:#333;text-decoration:none;cursor:pointer;margin: 0;padding: 0;}
a:active, a:hover {text-decoration:underline}
address,caption,cite,code,dfn,em,var {font-style:normal;font-weight:normal}
header,main,aside,article,footer {display:block}
@import "./partners/custom.scss";
