/* Color */
$jply_main_dark01: rgba(0, 168, 93, 1);
$jply_maincolor: rgba(0, 195, 98, 1);
$jply_main_light01: rgba(47, 221, 120, 1);
$jply_main_light02: rgba(126, 242, 169, 1);
$jply_main_light03: rgba(217, 255, 230, 1);
$jply_sub_dark01: rgba(9, 148, 255, 1);
$jply_subcolor: rgba(64, 182, 255, 1);
$jply_gray01: rgba(50, 52, 56, 1);
$jply_gray02: rgba(75, 76, 80, 1);
$jply_gray03: rgba(104, 106, 109, 1);
$jply_gray04: rgba(133, 135, 140, 1);
$jply_gray05: rgba(197, 199, 204, 1);
$jply_gray06: rgba(229, 230, 233, 1);
$jply_gray07: rgba(243, 243, 244, 1);
$jply_color_red: rgba(255, 87, 87, 1);
$jply_color_orange: rgba(255, 162, 43, 1);
$jply_color_purple: rgba(138, 116, 237, 1);

$gray30: #f9f9fb;
$gray50: #f3f3f4;
$gray100: #e5e6e9;
$gray200: #c5c7cc;
$gray300: #a4a6ad;
$gray400: #85878c;
$gray500: #686a6d;
$gray600: #57595b;
$gray700: #4b4c50;
$gray800: #323438;
$gray900: #242628;
$green30: #f7fdfa;
$green50: #ebf9ef;
$green100: #bbecc8;
$green200: #96e0aa;
$green300: #76d792;
$green400: #50cd7a;
$green500: #00c362;
$green600: #00a855;
$green700: #008040;
$green800: #006633;
$green900: #024b26;
$blue30: #f6fbfe;
$blue50: #ecf6fe;
$blue100: #bbdefb;
$blue200: #90caf9;
$blue300: #64b5f6;
$blue400: #42a5f5;
$blue500: #2196f3;
$blue600: #1e88e5;
$blue700: #1976d2;
$blue800: #1565c0;
$blue900: #0d47a1;
$red30: #fff7f7;
$red50: #fff0f2;
$red100: #ffcdcd;
$red200: #f6a2a2;
$red300: #f38181;
$red400: #ef5350;
$red500: #f44336;
$red600: #e53935;
$red700: #d32f2f;
$red800: #c62828;
$red900: #b71c1c;
$orange30: #fffaf5;
$orange50: #fff3e0;
$orange100: #ffe0b2;
$orange200: #ffcc80;
$orange300: #ffb74d;
$orange400: #ffa726;
$orange500: #ff9800;
$orange600: #fb8c00;
$orange700: #f57c00;
$orange800: #ef6c00;
$orange900: #e65100;
$purple30: #f9f8fc;
$purple50: #f2f0f9;
$purple100: #d0cbec;
$purple200: #aaa1dd;
$purple300: #8477cf;
$purple400: #6856c3;
$purple500: #523cc3;
$purple600: #4736b5;
$purple700: #3b2ead;
$purple800: #3127a0;
$purple900: #2b1b92;
$teal30: #f1fffd;
$teal50: #e0fffa;
$teal100: #b8f0e7;
$teal200: #87ded0;
$teal300: #63d3c1;
$teal400: #40c8b2;
$teal500: #1cbda3;
$teal600: #15a78f;
$teal700: #0e917b;
$teal800: #077a68;
$teal900: #006454;


/* Premium Color */
$premium_col: #1cbda3;
$premium_col_active: #139984;

.jply_main_dark0 { color: $jply_main_dark01; }
.jply_main { color: $jply_maincolor; }
.jply_main_light0 { color: $jply_main_light01; }
.jply_main_light1 { color: $jply_main_light02; }
.jply_main_light2 { color: $jply_main_light03; }
.jply_sub_dark0 { color: $jply_sub_dark01; }
.jply_sub { color: $jply_subcolor; }
.jply_gr0 { color: $jply_gray01; }
.jply_gr1 { color: $jply_gray02; }
.jply_gr2 { color: $jply_gray03; }
.jply_gr3 { color: $jply_gray04; }
.jply_gr4 { color: $jply_gray05; }
.jply_gr5 { color: $jply_gray06; }
.jply_gr6 { color: $jply_gray07; }
.jply_red { color: $jply_color_red; }
.jply_orange { color: $jply_color_orange; }
.jply_purple { color: $jply_color_purple; }



$gradient1:  linear-gradient(90deg, #9575CD 0%, #5C6BC0 100%);
$gradient2:  linear-gradient(180deg, #FFD34E 0%, #FFA726 100%);



/* Premium Color */
$premium_col: #1cbda3;
$premium_col_active: #139984;

/* mobile top header height */
$mobile_top_height: 56px;


/* Shadow */
$shadow1: 0px 1px 4px rgba(30, 40, 58, 0.04);
$shadow2: 0px 6px 48px rgba(30, 40, 58, 0.08);
$shadow3: 0px 6px 48px rgba(30, 40, 58, 0.08), 0px 6px 48px rgba(30, 40, 58, 0.08);
$shadow4: 0px 6px 48px rgba(30, 40, 58, 0.08), 0px 6px 48px rgba(30, 40, 58, 0.08), 0px 16px 48px 8px rgba(30, 40, 58, 0.08);



.jply_main_dark0 { color: $jply_main_dark01; }
.jply_main { color: $jply_maincolor; }
.jply_main_light0 { color: $jply_main_light01; }
.jply_main_light1 { color: $jply_main_light02; }
.jply_main_light2 { color: $jply_main_light03; }
.jply_sub_dark0 { color: $jply_sub_dark01; }
.jply_sub { color: $jply_subcolor; }
.jply_gr0 { color: $jply_gray01; }
.jply_gr1 { color: $jply_gray02; }
.jply_gr2 { color: $jply_gray03; }
.jply_gr3 { color: $jply_gray04; }
.jply_gr4 { color: $jply_gray05; }
.jply_gr5 { color: $jply_gray06; }
.jply_gr6 { color: $jply_gray07; }
.jply_red { color: $jply_color_red; }
.jply_orange { color: $jply_color_orange; }
.jply_purple { color: $jply_color_purple; }

$scale:0.5;

.jf_gray30{ color: $gray30; }
.jf_gray50{ color: $gray50; }
.jf_gray100 {color : $gray100;}
.jf_gray200 {color : $gray200;}
.jf_gray300 {color : $gray300;}
.jf_gray400 {color : $gray400;}
.jf_gray500 {color : $gray500;}
.jf_gray600 {color : $gray600;}
.jf_gray700 {color : $gray700;}
.jf_gray800 {color : $gray800;}
.jf_gray900 {color : $gray900;}


.jf_blue30{ color: $blue30; }
.jf_blue50{ color: $blue50; }
.jf_blue100 {color : $blue100;}
.jf_blue200 {color : $blue200;}
.jf_blue300 {color : $blue300;}
.jf_blue400 {color : $blue400;}
.jf_blue500 {color : $blue500;}
.jf_blue600 {color : $blue600;}
.jf_blue700 {color : $blue700;}
.jf_blue800 {color : $blue800;}
.jf_blue900 {color : $blue900;}

.jf_green30{ color: $green30; }
.jf_green50{ color: $green50; }
.jf_green100 {color : $green100;}
.jf_green200 {color : $green200;}
.jf_green300 {color : $green300;}
.jf_green400 {color : $green400;}
.jf_green500 {color : $green500;}
.jf_green600 {color : $green600;}
.jf_green700 {color : $green700;}
.jf_green800 {color : $green800;}
.jf_green900 {color : $green900;}

.jf_red30{ color: $red30; }
.jf_red50{ color: $red50; }
.jf_red100 {color : $red100;}
.jf_red200 {color : $red200;}
.jf_red300 {color : $red300;}
.jf_red400 {color : $red400;}
.jf_red500 {color : $red500;}
.jf_red600 {color : $red600;}
.jf_red700 {color : $red700;}
.jf_red800 {color : $red800;}
.jf_red900 {color : $red900;}


.jf_orange30{ color: $orange30; }
.jf_orange50{ color: $orange50; }
.jf_orange100 {color : $orange100;}
.jf_orange200 {color : $orange200;}
.jf_orange300 {color : $orange300;}
.jf_orange400 {color : $orange400;}
.jf_orange500 {color : $orange500;}
.jf_orange600 {color : $orange600;}
.jf_orange700 {color : $orange700;}
.jf_orange800 {color : $orange800;}
.jf_orange900 {color : $orange900;}

.jf_purple30{ color: $purple30; }
.jf_purple50{ color: $purple50; }
.jf_purple100 {color : $purple100;}
.jf_purple200 {color : $purple200;}
.jf_purple300 {color : $purple300;}
.jf_purple400 {color : $purple400;}
.jf_purple500 {color : $purple500;}
.jf_purple600 {color : $purple600;}
.jf_purple700 {color : $purple700;}
.jf_purple800 {color : $purple800;}
.jf_purple900 {color : $purple900;}

.jf_teal30{ color: $teal30; }
.jf_teal50{ color: $teal50; }
.jf_teal100 {color : $teal100;}
.jf_teal200 {color : $teal200;}
.jf_teal300 {color : $teal300;}
.jf_teal400 {color : $teal400;}
.jf_teal500 {color : $teal500;}
.jf_teal600 {color : $teal600;}
.jf_teal700 {color : $teal700;}
.jf_teal800 {color : $teal800;}
.jf_teal900 {color : $teal900;}

.jf_gradient1 {color : $gradient1;}
.jf_gradient2 {color : $gradient2;}

.jf_shadow1 {box-shadow: $shadow1;}
.jf_shadow2 {box-shadow: $shadow2;}
.jf_shadow3 {box-shadow: $shadow3;}
.jf_shadow4 {box-shadow: $shadow4;}

.jf_text_right {text-align: right;}
.jf_text_left {text-align: left;}

.jf_flex_right {justify-content: right;}
.jf_flex_left {justify-content: left;}
.jf_flex_between {justify-content: space-between;}