@import "../../jply_common/_val";
@import "../../jply_common/_badge";

.lnb_menu {
  position: relative;
  min-height: 700px;
  flex-basis: 280px;
  flex-shrink: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(30, 40, 58, 0.12);
  border-right: 1px solid $gray30;
  z-index: 1;
  &:after {
    display:block;
    position: absolute;
    height: 36px;
    bottom:0;
    left:0;
    right:0;
    z-index:1;
    background: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
    content:'';
  }
  &__wrap{
    &::-webkit-scrollbar {
      width: 15px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-clip: padding-box;
      border: 4px solid transparent;
      background-color: $gray100;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding: 8px 0 120px;
    box-sizing: border-box;
  }
  &__top {
    display: flex;
    justify-content: center;
    .job_register_btn {
      width: 216px;
      margin: 32px 0 8px;
    }
  }

  &__list {
    .menu_list {
      width: 279px;
      padding-left: 16px;
      &__item {
        width: 100%;
        display: inline-block;
        padding: 0 32px 0 16px;
        margin-top: 24px;
        height: 40px;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        color: $gray800;
        display: flex;
        justify-content: space-between;
        i {
          align-items: center;
          display: flex;
          color: $gray200;
          font-size: 18px;
        }
        &:hover, &.is_active {
          background: $gray30;
          border-radius: 20px 0px 0px 20px;
        }
      }
      &__group {
        padding-left: 16px;
      }
      &__btn {
        width: calc(100% - 48px);
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $gray300;
        margin: 2px 16px 0 16px;
        &:hover {
          border-color: $gray30;
          background-color: $gray30;
          color: $gray400;
        }
      }
      &__sub {
        display: flex;
        justify-content: space-between;
        width: 216px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        padding-top: 20px;
        &.is_active {
          font-weight: bold;
          color: $gray800;
        }
        &:first-of-type {
          padding-top: 10px;
        }
        &:last-of-type {
          padding-bottom: 10px;
        }
        .list_name {
          display: flex;
          align-items: center;
          .jp_badge {
            text-decoration-line: none !important;
          }
        }
        .list_count {
          max-width: 36px;
          &:hover {
            text-decoration-line: underline;
          }
        }
        .sub_text {
          padding-left: 4px;
          line-height: 24px;
          max-width: 153px;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          overflow: hidden;
          &:hover {
            text-decoration-line: underline;
          }
        }
      }
    }
  }
}
