@import "_val";

.jp_badge {
  padding: 2px 6px 3px;
  height: 20px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;

  &.badge_red {
    background: $red50;
    color: $red500;
  }
  &.badge_blue {
    background: $blue50;
    color: $blue500;
  }
  &.badge_green {
    background: $green50;
    color: $green500;
  }
  &.badge_gray {
    background: $gray50;
    color: $gray500;
  }
  &.badge_empty {
    background: #fff;
    color: $gray500;
    border: 1px solid $gray100;
  }
}
