@charset "utf-8";
/*@import url(//cdn.rawgit.com/hiun/NanumSquare/master/nanumsquare.css);*/

@font-face {
    font-family: 'SpoqaB';
    src: url('../fonts/SpoqaHanSansBold.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SpoqaR';
    src: url('../fonts/SpoqaHanSansRegular.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'SpoqaL';
    src: url('../fonts/SpoqaHanSansLight.woff2') format('woff2'),
    url('../fonts/SpoqaHanSansLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* reset */
body,
select,
input,
textarea,
svg {
    font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif !important;
}

body {
    width: 100%;
    background-color: #f5f5f5;
    font-size: 15px;
    color: #444;
    overflow-x: auto !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h1 {
    font-size: 25px;
    font-family: 'SpoqaB', '돋움', dotum, Arial, sans-serif !important;
}

h2 {
    font-size: 25px;
}

h3 {
    font-size: 25px;
}

h4,
h5,
h6 {
    font-size: 17px;
}

ul,
li,
dl,
p {
    margin: 0;
    padding: 0;
}

ul {
    list-style-position: inside;
    li {
        list-style: none;
    }
}

a,
a:hover {
    color: #444;
}

em {
    font-style: normal;
}

/* variable */

$main_gray_dark: #333333;
$main_gray_deep: #444444;
$main_gray_normal: #666666;
$main_gray_light: #999999;
$main_gray_very_light: #d8d8d8;
$main_green_light: #A9DBA8;
$main_green_normal: #7BBC9C;
$main_green_deep: #3C8787;
$main_blue_dark: #074061;
$emph_red: #FD5E52;
$emph_orange: #F5A623;
$emph_light_green: #7ED321;
$emph_blue: #4A90E2;
$emph_deep_green: #3C8787;
$left_width: 240px;
/* layout */

body.fixed-nav #wrapper .navbar-static-side,
body.fixed-nav #wrapper #page-wrapper {
    margin-top: 0px;
}

body.fixed-nav #page-wrapper {
    padding: 35px 25px 0;
}

.pace-done .navbar-static-side,
.pace-done .nav-header,
.pace-done li.active,
.pace-done #page-wrapper,
.pace-done .footer {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
}

@media (min-width: 768px) {
    #page-wrapper {
        margin: 0 0 0 $left_width;
        border-left: 1px solid #E0E0E0;
    }
}

@media (max-width: 768px) {
    #page-wrapper {
        margin: 0;
        border-left: 0 none;
    }
}

/* navigation header */

.nav-header {
    padding: 40px 25px;
    background: transparent;
}

.navbar-header-top {
    border-bottom: 1px solid #e0e0e0;
    .brand_spot {
        &:only-of-type {
            margin-bottom: 30px;
        }
    }
    .navbar-brand {
        display: block;
        height: auto;
        padding: 0;
        float: none;
    }
    .page_name {
        margin-top: 9px;
        margin-bottom: 14px;
        font-size: 13px;
        color: $main_gray_light;
        line-height: 1.3;
    }
}

.jp_admin_llogo {
    overflow: hidden;
    display: block;
    width: 68px;
    height: 68px;
    font-size: 0;
    margin-right: 15px;
    border: solid 1px #bdbdbd;
    border-radius: 4px;
    img {
        width: 100%;
    }
}

.jp_admin_lstar {
    display: inline-block;
    margin-top: 9px;
    font-size: 14px;
    color: $main_gray_normal;
    &>i {
        display: inline-block;
        margin-right: 6px;
        color: #fba208;
    }
}

.navbar_header_box {
    position: relative;
    width: 100%;
    min-height: 68px;
    margin-top: 26px;
    box-sizing: border-box;
    .tit {
        overflow: hidden;
        display: block;
        margin-top: 15px;
        color: #fff;
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $main_gray_normal;
        font-size: 15px;
    }
    .tit_sub {
        display: block;
        margin: 0 auto;
        max-width: 90%;
        color: #999;
        padding-top: 5px;
        overflow: hidden;
        white-space: nowrap;
    }
    .rate_ty02 {
        position: absolute;
        bottom: 15px;
        left: 50%;
        width: 50px;
        margin-left: -25px;
    }
    &:after {
        display: block;
        content: '';
        clear: both;
    }
}

/* navigation */

body.fixed-sidebar .navbar-static-side {
    width: $left_width;
    background-color: #fff;
}

.nav {
    line-height: 1.3;
    font-size: 13px;
    &>li {
        &>a {
            padding: 17px 25px;
            color: $main_gray_light;
        }
        &>a>i {
            margin-right: 6px;
        }
        &>a,
        &>.nav_title {
            font-weight: normal;
            color: $main_gray_light;
        }
        &.active {
            border-left: none;
            background-color: #f9f9f9 !important;
            &>a {
                color: $main_gray_deep;
            }
        }
    }
    .nav_title {
        position: relative;
        display: block;
        padding: 16px 47px 16px 41px;
    }
}

ul.nav-second-level {
    background-color: #f9f9f9;
}

.nav-second-level li a {
    padding: 13px 45px;
}

.navbar-default .nav>li>a:hover,
.navbar-default .nav>li>a:focus {
    background-color: #f9f9f9;
    color: $main_gray_deep;
}

.nav-second-level li:last-child {
    margin-bottom: 20px;
}

.metismenu {
    .arrow {
        transform-origin: right;
        transform: translate(-6px, -2px) rotate(270deg);
        -webkit-transform: translate(-6px, -2px) rotate(270deg);
    }
    .active {
        .arrow {
            transform-origin: right;
            transform: translate(-10px, 0) rotate(180deg);
            -webkit-transform: translate(-10px, 0) rotate(180deg);
        }
    }
}

/*helper add*/

.bg-none {
    background-color: transparent;
}

.bg-default {
    background-color: #fff;
}

.p-l-n {
    padding-left: 0;
}

.p-r-n {
    padding-right: 0;
}

.border-default {
    border-radius: 2px;
    border: solid 1px #e0e0e0;
}

.jp_max_width_1020 {
    max-width: 1020px;
}

.jp_max_width_1030 {
    max-width: 1030px;
}

/* jp style guide */

.ele_class_name {
    font-size: 12px;
}

$color_map: ( danger: $emph_red,
warning: $emph_orange,
caution: $emph_light_green,
dpgreen: $main_green_deep,
nomlgreen: $main_green_normal,
ltgreen: $main_green_light,
darkblue: $main_blue_dark);
@each $status,
$color in $color_map {
    .label,
    .badge {
        &-jp-#{""+$status} {
            background-color: $color;
            color: #fff;
        }
    }
}

/* 강조 text */

.danger,
.emph_red {
    color: $emph_red;
    font-weight: bold;
}

.warning,
.emph_orange {
    color: $emph_orange;
    font-weight: bold;
}

.caution,
.emph_light_green {
    color: $emph_light_green;
    font-weight: bold;
}

/* Button */

.btn-jp-cycle,
.btn-jp-gray {
    min-width: 100px;
    padding: 8px 12px 10px;
}

.btn-jp-deepgreen {
    background-color: #3C8787;
    border-color: #3C8787;
    color: #fff;
    &:hover {
        background-color: #619c9d;
        border-color: #619c9d;
        color: #fff;
    }
    &:focus {
        color: #fff;
    }
}

.btn-jp-gray {
    background: #f4f4f4;
    margin-right: 10px;
    border-color: #bdbdbd;
    color: #666;
    &.active,
    &:focus {
        font-family: 'SpoqaB';
        color: #3c8687;
        border-color: #3c8687;
        background-color: rgba(60, 134, 135, 0.1);
        box-shadow: none;
    }
    &:nth-last-child {
        margin-right: 0;
    }
    &:hover {
        background-color: #f4f4f4;
        border-color: #bdbdbd;
    }
    &.active {
        &:focus,
        &:active,
        &:hover {
            color: #3c8687;
            border-color: #3c8687;
            background-color: rgba(60, 134, 135, 0.1);
            box-shadow: none;
        }
    }
}

.btn-jp-cycle {
    background: #fff;
    border-color: #bdbdbd;
    &.active,
    &:focus {
        color: #3c8687;
        border-color: #3c8687;
        background-color: rgba(60, 134, 135, 0.1);
        box-shadow: none;
    }
    &:hover {
        background-color: #f4f4f4;
    }
    &.active {
        &:focus,
        &:active,
        &:hover {
            color: #3c8687;
            border-color: #3c8687;
            background-color: rgba(60, 134, 135, 0.1);
            box-shadow: none;
        }
    }
}

.btn-jp-more {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 10px;
    line-height: 2;
    font-size: 15px;
    background-color: transparent;
    border-radius: 2px;
}

.btn_more_list {
    background-color: #fafafa;
    border: solid 1px #e0e0e0;
    color: #666;
    &:hover,
    &:focus {
        font-weight: bold;
    }
}

.btn_more_list_green {
    background-color: rgba(60, 134, 135, 0.1);
    border: solid 1px rgba(60, 134, 135, 0.5);
    color: #3c8687;
    &:hover,
    &:focus {
        background-color: rgba(60, 134, 135, 0.2);
        color: #3c8687;
    }
}

/* label type */

.label.label-jp-lg {
    box-sizing: border-box;
    display: inline-block;
    height: 40px;
    padding: 8px 10px 7px;
    letter-spacing: -0.2px;
    font-size: 20px;
    line-height: 1.2;
    border-radius: 3px;
    vertical-align: top;
}

/* badge */

.badge-jp {
    box-sizing: border-box;
    display: inline-block;
    padding: 3px 7px 4px;
    border: 1px solid transparent;
    border-radius: 2px;
    font-size: 10px;
    line-height: 1.1;
    vertical-align: top;
}

.badge_group {
    display: inline-block;
}

/* tab */

.tabs-container .nav-tabs>li.active>a:hover,
.tabs-container .nav-tabs>li.active>a:focus {
    border-top: 1px solid #E0E0E0;
    border-left: 1px solid #E0E0E0;
    border-right: 1px solid #E0E0E0;
    border-bottom: 1px solid #fff;
}
