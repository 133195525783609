@charset "utf-8";
$assets_path: "https://common.jobplanet.co.kr/images/";
body {
  overflow-x: auto !important;
}

/* 2019 02 21 - add by yys (spa start) */
@import "./alri_spa_reset.scss";
@import "./bootstrap/css/bootstrap.scss";
/* // add by yys (spa start) */

/* 2019 02 21 - add by yys (spa start - 기존에 alri에 함께 link 되어 있던 스타일시트) */
@import "./jp_private_common.scss";
@import "./jp_skin.scss";

/* 기존 알리 파일에 import 되어 있던 파일 */
@import "./alri/reset.scss";
@import "./alri/print.scss";

/* jobplanet에서는 partners_x에 import 되어 있었음 */
@import "./inspinia/scss/font-awesome.scss";
@import "./jply_common/_form.scss";
@import "./jply_common/_button.scss";
@import "./admin/hrinsight_outside.scss";
/* variable */
$main_gray_dark: #333333;
$main_gray_deep: #444444;
$main_gray_normal: #666666;
$main_gray_light: #999999;
$main_gray_very_light: #d8d8d8;

$main_green_light: #a9dba8;
$main_green_normal: #7bbc9c;
$main_green_deep: #3c8787;
$main_green_dark: #074061;

$emph_red: #fd5e52;
$emph_orange: #f5a623;
$emph_light_green: #7ed321;
$emph_blue: #4a90e2;
$emph_deep_green: #3c8787;

$alri_header_height: 79px;

/* add by yys on 0225 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
/* emphasis color */
.text-danger,
.danger,
.emph_red {
  color: $emph_red;
  font-weight: bold;
}
.warning,
.emph_orange {
  color: $emph_orange;
  font-weight: bold;
}
.caution,
.emph_green,
.emph_light_green {
  color: $emph_light_green;
  font-weight: bold;
}
.text-success,
.safe,
.emph_blue {
  color: $emph_blue;
  font-weight: bold;
}
.emph_deep_green {
  color: $emph_deep_green;
  font-weight: bold;
}
.emph_dark_green {
  color: $main_green_dark;
  font-weight: bold;
}
.text-primary {
  color: #333;
}
.col_light_gray {
  color: $main_gray_light;
}

@mixin hover_underline_style() {
  text-decoration: underline;
  .danger,
  .emph_red {
    color: #dd1000;
    text-decoration: underline;
    text-decoration-color: #dd1000;
  }
  .warning,
  .emph_orange {
    color: #f97500;
    text-decoration: underline;
    text-decoration-color: #f97500;
  }
  .caution,
  .emph_green,
  .emph_light_green {
    color: #5eb500 !important;
    text-decoration: underline;
    text-decoration-color: #5eb500;
  }
  .safe,
  .emph_blue {
    color: #135cb1;
    text-decoration: underline;
    text-decoration-color: #135cb1;
  }
}

$col_types: (
  1: $main_green_dark,
  2: $main_green_deep,
  3: $main_green_normal,
  4: $main_green_light,
  5: #a8d0db,
  6: #7bafbc,
  7: #3c6687,
  8: #533c87,
  9: #8e7bbc,
  10: #b0a8db,
  11: #d1a8db,
  12: #bc7bb0
);

%colorlist {
  @each $type, $color in $col_types {
    &:nth-of-type(12n - #{(12 - $type)}) {
      color: $color;
      .count_content,
      .legend_content {
        color: $color;
      }
    }
  }
}
%background-colorlist {
  @each $type, $color in $col_types {
    &:nth-of-type(12n - #{(12 - $type)}) {
      background-color: $color;
    }
  }
}

.color_item {
  @extend %colorlist;
}

/* label type */
.label {
  &_default,
  &_danger,
  &_warning,
  &_caution {
    box-sizing: border-box;
    display: inline-block;
    height: 40px;
    padding: 8px 10px 7px;
    color: #fff;
    letter-spacing: -0.2px;
    text-align: center;
    font-size: 20px;
    line-height: 1.2;
    border-radius: 3px;
    vertical-align: top;
  }
  &_danger {
    background-color: $emph_red;
  }
  &_warning {
    background-color: $emph_orange;
  }
  &_caution {
    background-color: $emph_light_green;
  }
}

/* tag type */
.tag {
  &_default,
  &_danger,
  &_warning,
  &_caution {
    box-sizing: border-box;
    display: inline-block;
    margin: 0 6px;
    padding: 9px 15px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #fff;
    font-size: 15px;
    &:hover {
      text-decoration: none;
    }
  }
  &_default {
    color: $main_gray_light;
    border-color: $main_gray_light;
    &:hover,
    &.active {
      color: $main_gray_normal;
      background-color: $main_gray_very_light;
    }
  }
  &_danger {
    color: $emph_red;
    border-color: $emph_red;
    &:hover,
    &.active {
      color: $emph_red;
      background-color: #ffdfdc;
    }
  }
  &_warning {
    color: $emph_orange;
    border-color: $emph_orange;
    &:hover,
    &.active {
      color: $emph_orange;
      background-color: #fdedd3;
    }
  }
  &_caution {
    color: $emph_light_green;
    border-color: $emph_light_green;
    &:hover,
    &.active {
      color: $emph_light_green;
      background-color: #e5f6d3;
    }
  }
}

.tag_box {
  position: relative;
  margin: -7px -6px;
  .tag {
    &_danger,
    &_warning,
    &_caution {
      margin: 7px 6px;
    }
  }
}

/* button */
.btn {
  &_default,
  &_danger,
  &_warning,
  &_caution {
    box-sizing: border-box;
    display: inline-block;
    padding: 9px 15px 8px;
    border: 1px solid transparent;
    border-radius: 3px;
    background-color: #fff;
    font-size: 15px;
    text-align: center;
  }
  &_default {
    color: $main_gray_light;
    border-color: $main_gray_light;
    &:hover,
    &:focus {
      color: $main_gray_light;
    }
  }
  &_danger {
    color: $emph_red;
    border-color: $emph_red;
    &:hover,
    &:focus {
      color: $emph_red;
    }
  }
  &_warning {
    color: $emph_orange;
    border-color: $emph_orange;
    &:hover,
    &:focus {
      color: $emph_orange;
    }
  }
  &_caution {
    color: $emph_light_green;
    border-color: $emph_light_green;
    &:hover,
    &:focus {
      color: $emph_light_green;
    }
  }
}
.btn-jp {
  &-deepgreen {
    background-color: $main_green_deep;
    border-color: $main_green_deep;
    color: #fff;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}
.btn_group {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  &.align_right {
    text-align: right;
  }
  &.align_center {
    text-align: center;
  }
}

/* badge */
.badge {
  &_default,
  &_danger,
  &_warning,
  &_caution,
  &_dark_blue,
  &_deep_green {
    box-sizing: border-box;
    display: inline-block;
    padding: 3px 4px;
    border: 1px solid transparent;
    border-radius: 2px;
    font-size: 10px;
    color: #fff;
    text-align: center;
    line-height: 1.2;
    vertical-align: top;
  }
  &_default {
    background-color: $main_gray_light;
    border-color: $main_gray_light;
  }
  &_danger {
    background-color: $emph_red;
    border-color: $emph_red;
  }
  &_warning {
    background-color: $emph_orange;
    border-color: $emph_orange;
  }
  &_caution {
    background-color: $emph_light_green;
    border-color: $emph_light_green;
  }
  &_dark_blue {
    background-color: $main_green_dark;
    border-color: $main_green_dark;
  }
  &_deep_green {
    background-color: $main_green_deep;
    border-color: $main_green_deep;
  }
}

.logo_new {
  position: absolute;
  top: 40px;
  left: 35px;
  width: 150px;
  height: 30px;
  overflow: hidden;
  text-indent: -100px;
  background: transparent
    url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/logo_type_1.png)
    0 0 no-repeat;
  background-size: 100% auto;
  z-index: 5;
}

/* gnb right selectbox */
.alri_term_box {
  margin-top: 15px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 200px;
  height: 40px;
  line-height: 40px;
  .control-label {
    display: block;
    position: relative;
    width: 100%;
    height: 40px;
    visibility: hidden;
  }
  .form-control {
    position: absolute;
    box-sizing: content-box;
    top: 0;
    right: 0;
    width: 100%;
    height: 40px;
    display: block;
    border: none;
    box-sizing: border-box;
    border: 1px solid #bdbdbd;
    border-radius: 2px;
    font-size: 14px;
    line-height: 38px;
    color: $main_gray_light;
    background-color: #ffffff;
    &:focus {
      border-color: #bdbdbd !important;
    }
  }
}

/* layout */
.alri_body {
  position: relative;
  width: 100%;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: $left_width;
    height: 100%;
    background-color: #fdfdfd;
  }
}
#page-wrapper {
  max-width: 1051px;
}
.company_info {
  margin-top: 34px;
  position: relative;
  padding-left: 125px;
  box-sizing: border-box;
  .img_box {
    position: absolute;
    width: 64px;
    height: 64px;
    left: 44px;
  }
}

/* 컨텐츠영역 */
.page-heading {
  padding: 20px 0;
  h1 {
    margin: 0 20px 10px 0;
    font-weight: bold;
    display: inline-block;
  }
  .navbar-static-top {
    .glyphicon {
      top: 4px;
      position: relative;
      top: 3px;
    }
  }
}
.inner_wrap {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  & ~ .inner_wrap {
    margin-top: 40px;
  }
}
.ibox-title {
  border-top: none;
  background-color: transparent;
  padding: 0 0 5px;
  min-height: auto;
  position: relative;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  p {
    margin-top: 15px;
    line-height: 30px;
    margin-bottom: 5px;
  }
  .title_comment {
    display: inline-block;
    margin-left: 20px;
    color: $main_green_deep;
    font-size: 15px;
  }
  .left_description {
    float: left;
  }
  .right_description {
    float: right;
    margin-top: 15px;
    line-height: 30px;
    margin-bottom: 5px;
    letter-spacing: 0.1px;
    text-align: right;
    color: $main_gray_light;
  }
}
.ibox-content {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  padding: 30px;
  & ~ .ibox-content {
    margin-top: 20px;
  }
}
.box_wrap {
  position: relative;
}
.list_ty1 {
  li {
    margin: 15px 0;
    word-break: break-all;
    word-wrap: break-word;
    .col_light_gray {
      margin-left: 9px;
      font-size: 13px;
    }
    &:first-child {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 5px;
    }
  }
}
.ibox {
  dl {
    margin-bottom: 0;
    margin-bottom: 25px;
    a:last-child dl {
      margin-bottom: 0;
    }
  }
}

/* realnews */
.alri_realnews {
  .realnews_item {
    display: block;
    margin-top: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    &:first-of-type {
      margin-top: 10px;
    }
    dl {
      margin-bottom: 0;
    }
    &:hover {
      .realnews_title {
        text-decoration: underline;
      }
    }
  }
  .realnews_title,
  .realnews_content {
    overflow: hidden;
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;
  }
  .realnews_title {
    overflow: hidden;
    display: block;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    color: $main_gray_deep;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .realnews_content {
    overflow: hidden;
    display: block;
    max-height: 60px;
    margin-bottom: 20px;
    font-size: 15px;
    color: $main_gray_normal;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .realnews_info_area {
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    .realnews_keyword {
      float: left;
      margin-right: 15px;
      line-height: 30px;
      color: $main_gray_normal;
    }
    .info {
      float: right;
      font-size: 15px;
      line-height: 30px;
      color: $main_gray_light;
    }
    .date {
      margin-left: 15px;
    }
  }
  & + .btn_more_list {
    margin-top: 20px;
  }
}

/* chart */
.alri_chart {
  font-size: 17px;
  position: relative;
  min-height: 60px;
  h3 {
    margin-bottom: 30px;
  }
  .top_a {
    position: relative;
    box-sizing: border-box;
    h4 {
      margin-bottom: 30px;
      font-size: 20px;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    .top_right {
      position: absolute;
      right: 0;
      top: 30px;
      font-size: 20px;
      letter-spacing: -0.2px;
      span {
        margin: 0 20px 0 10px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .chart {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  & ~ .box_result_comment,
  & ~ .alri_chart {
    margin-top: 30px;
  }
  &.total {
    height: 400px;
    border: 0 none;
  }
  &.page {
    height: 280px;
  }
}
.legend_list {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}
.md_dounut {
  height: 100%;
  margin: 0 auto;
}
.page .chart {
  height: 280px;
  box-sizing: border-box;
}
.total .chart {
  .md_dounut {
    height: 280px;
  }
}
.sm_dounut {
  height: 220px;
  margin: 0 auto;
}

/* dounut chart legend */
.alri_chart_legend {
  display: table;
  min-height: 220px;
  text-align: center;
  width: 100%;
  .legend_list {
    display: table-row;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    .col-xs-3,
    .col-xs-6 {
      display: table-cell;
      float: none !important;
      overflow: hidden;
    }
  }
  .legend_item {
    box-sizing: border-box;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    @extend %colorlist;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .legend_title {
    text-align: center;
    color: $main_gray_deep;
  }
  .legend_content {
    text-align: center;
  }
  &.md_size {
    min-height: auto;
    .legend_title {
      font-size: 13px;
      padding: 6px 0;
    }
    .legend_content {
      font-size: 25px;
    }
  }
  &.sm_size {
    .legend_title {
      font-size: 13px;
      padding: 6px 0;
    }
    .legend_content {
      font-size: 22px;
    }
  }
}

/* big count number type  */
.box_result_list {
  .alri_count:only-of-type {
    padding-top: 120px;
  }
  .site_count:only-of-type {
    padding-top: 90px;
  }
}

.alri_count,
.site_count {
  position: relative;
  padding: 30px 0;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  .count_list {
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  .count_item {
    float: left;
  }
  .count_title {
    display: block;
    color: $main_gray_deep;
  }
  .count_content {
    display: block;
  }
  .count_number {
    font-weight: normal;
  }
}
.alri_count {
  .count_item {
    width: 27%;
    padding: 0 10px 0 0;
    &:last-of-type {
      width: 19%;
      padding-right: 0;
      text-align: right;
      .count_date {
        display: inline-block;
        margin-top: 68%;
        font-size: 15px;
        color: $main_gray_normal;
      }
    }
  }
  .count_number {
    font-size: 40px;
  }
  .count_title {
    margin-bottom: 15px;
    font-size: 20px;
  }
}
.site_count {
  .count_number {
    font-size: 30px;
  }
  .count_title {
    margin-bottom: 10px;
    font-size: 15px;
  }
}

.keryword_piechart_box {
  .alri_chart_legend {
    display: table;
    min-height: 220px;
    text-align: center;
    width: 100%;
    .legend_list_wrap {
      display: table-row;
    }
    .legend_list {
      display: table-cell;
      vertical-align: middle;
      text-align: left;
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      .col-xs-3,
      .col-xs-6 {
        float: none !important;
        overflow: hidden;
      }
    }
    .legend_item {
      display: inline-block;
      box-sizing: border-box;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      width: 47% !important;
    }
  }
}
.site_count {
  .count_item {
    @extend %colorlist;
  }
}

/* notice list */
.alri_notice,
.page_notice {
  position: relative;
  margin-bottom: 30px;
  &:before {
    content: "";
    display: block;
    border-top: 1px solid #d8d8d8;
    position: absolute;
    top: -12px;
    left: 0;
    width: 100%;
  }
  .notice_list {
    padding: 10px 0;
  }
  .notice_item {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding-left: 17px;
    margin: 5px 0;
    &:before {
      position: absolute;
      top: 10px;
      left: 0;
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 3.5px;
      content: "";
      @extend %background-colorlist;
    }
  }
  .notice_text {
    overflow: hidden;
    display: inline-block;
    max-width: 87%;
    height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;
    word-break: break-all;
    word-wrap: break-word;
  }
  .notice_date {
    display: inline-block;
    margin-left: 10px;
    color: $main_gray_light;
    font-size: 13px;
    vertical-align: top;
    line-height: 30px;
  }
}
.page_notice {
  margin-bottom: 0px;
}
.alri_notice {
  .notice_text {
    a:hover {
      @include hover_underline_style();
    }
  }
}

/* comment type */
.box_result_comment {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  background-color: #fafafa;
  text-align: center;
  .alri_comment {
    border-top: 1px solid #e0e0e0;
    font-size: 15px;
    line-height: 30px;
    word-break: keep-all;
    word-wrap: break-word;
    &:first-child {
      border-top: 0;
    }
    a:hover {
      @include hover_underline_style();
    }
  }
  & ~ .alri_chart {
    margin-top: 30px;
  }
}

.alri_comment_type_a {
  padding: 20px 30px;
}
.alri_comment_type_b {
  padding: 30px;
}
.alri_comment_type_c {
  padding: 30px;
  text-align: left;
  .comment_item {
    position: relative;
    margin-top: 20px;
    padding-left: 97px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    .tit {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      margin-right: 10px;
      font-weight: bold;
      line-height: 30px;
    }
    .content {
      display: inline;
      word-break: break-all;
      word-wrap: break-word;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.alri_comment_type_d {
  padding: 20px 110px;
  text-align: center;
}

.comment_icon {
  float: left;
}

/* table type */
.alri_table {
  position: relative;
  margin-bottom: 20px;
  table {
    width: 100%;
  }
  .alri_th {
    font-size: 15px;
    font-weight: normal;
    color: $main_gray_deep;
    line-height: 2;
    font-size: 20px;
    line-height: 1.5;
  }
  .alri_thead {
    th {
      box-sizing: border-box;
      padding: 0 20px;
    }
    .alri_th {
      display: block;
      padding: 20px 0;
      margin-bottom: 20px;
      border-bottom: 1px solid #e0e0e0;
      text-align: center;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .alri_tbody {
    tr {
      .alri_th,
      .alri_td {
        height: 64px;
      }
      .alri_th {
        font-size: 15px;
        line-height: 30px;
      }
      .alri_th:before {
        float: left;
        display: block;
        width: 7px;
        height: 7px;
        margin-top: 10px;
        margin-right: 10px;
        border-radius: 3px;
        content: "";
      }
      &:nth-of-type(4n - 3) {
        .alri_th:before {
          background-color: $main_green_dark;
        }
      }
      &:nth-of-type(4n - 2) {
        .alri_th:before {
          background-color: $main_green_deep;
        }
      }
      &:nth-of-type(4n - 1) {
        .alri_th:before {
          background-color: $main_green_normal;
        }
      }
      &:nth-of-type(4n) {
        .alri_th:before {
          background-color: $main_green_light;
        }
      }
    }
    .alri_td {
      box-sizing: border-box;
      padding: 0 10px;
      font-size: 25px;
      color: $main_gray_light;
      text-align: center;
      .number {
        font-size: 25px;
      }
    }
    .count_bold,
    .count {
      margin-right: 0px;
      font-size: 25px;
      line-height: 30px;
    }
    .count_bold {
      color: #212121;
    }
    .arrow_num {
      display: inline-block;
      width: 62px;
      margin-left: 16px;
      font-size: 14px;
      text-align: left;
      line-height: 2;
      letter-spacing: -1px;
      & > i {
        margin-right: 10px;
        font-size: 20px;
      }
    }
  }
}

/* keyword title type */
.alri_keyword_title {
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  line-height: 30px;
  margin-bottom: 14px;
  .alri_keyword_label {
    float: left;
  }
  .keyword_text {
    font-size: 25px;
    line-height: 40px;
    margin: 0 13px 0 20px;
    & + span {
      font-size: 25px;
      line-height: 40px;
    }
    &.large {
      font-size: 30px;
      & + span {
        font-size: 30px;
      }
    }
  }
  .btn_default {
    float: right;
    margin-bottom: 0;
  }
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
.alri_keyword_title {
  & + .inner_wrap {
    border-top: 1px solid $main_gray_very_light;
    .top_a {
      padding: 28px 0 20px;
      margin: 0;
    }
  }
}

/* keyword review content type */
.review_wrap {
  position: relative;
  margin: 20px 0 30px;
  & ~ .alri_chart {
  }
}
.alri_keyword_review {
  .review_item {
    margin: 10px 0;
    &:before {
      float: left;
      display: block;
      width: 7px;
      height: 7px;
      margin-top: 6px;
      margin-right: 10px;
      border-radius: 3.5px;
      background-color: $main_gray_light;
      content: "";
    }
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  &.keyword_danger {
    .review_item {
      &:before {
        background-color: $emph_red;
      }
    }
  }
  &.keyword_warning {
    .review_item {
      &:before {
        background-color: $emph_orange;
      }
    }
  }
  &.keyword_caution {
    .review_item {
      &:before {
        background-color: $emph_light_green;
      }
    }
  }
  .comunity_info {
    display: inline-block;
    margin-right: 20px;
    line-height: 20px;
    vertical-align: top;
    span:only-of-type,
    .comunity_name:only-of-type {
      margin-right: 0;
    }
  }
  .comunity_name {
    margin-right: 20px;
    color: $main_gray_light;
    line-height: 20px;
  }
  .user_id {
    line-height: 20px;
  }
  .review_text {
    overflow: hidden;
    display: inline-block;
    max-width: 70%;
    height: 20px;
    line-height: 20px;
    word-break: break-all;
    word-wrap: break-word;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date {
    display: inline-block;
    margin-left: 10px;
    line-height: 20px;
    font-size: 11px;
    color: $main_gray_light;
    vertical-align: top;
    & + span {
      line-height: 20px;
      vertical-align: top;
    }
  }
}
.site_review {
  & ~ .box_result_comment {
    margin-top: 30px;
  }
}
#alri_scatter {
  height: 250px;
}

/* no data layer */
.no_data_layout {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  .no_data_message {
    position: absolute;
    top: 50%;
    width: 80%;
    padding: 30px;
    margin: -42px 10% 0;
    border-radius: 12px;
    font-size: 17px;
    text-align: center;
    word-break: keep-all;
    word-wrap: break-word;
  }
  .no_data_img {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 101;
    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    > img {
      width: 100%;
      height: auto;
    }
  }
  .no_data_message_ty2 {
    padding: 277px 50px 135px;
    text-align: center;
    font-size: 20px;
    background: url("../images/icon_thumbs_up.svg") 50% 149px no-repeat;
    color: $main_gray_normal;
    .large_text {
      display: block;
      margin-bottom: 15px;
      font-size: 25px;
    }
  }
}
.no-data {
  display: none !important;
}

@media (max-width: 768px) {
  .body-small .navbar-static-side {
    display: block;
    position: absolute;
  }
}

.navbar-form .form-group {
  display: block;
  margin-bottom: 0;
  vertical-align: middle;
}
.navbar-right {
  float: right !important;
  margin-right: 0;
  margin-bottom: 0;
}

#alri_KeyWordTotal {
  position: relative;
  z-index: 1;
  .keywordbar_chart {
    padding-top: 0;
    padding-bottom: 0;
  }
  .custom_line {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    width: 1px;
  }
  .alri_keryword_chrat {
    &.danger {
    }
  }
}

.keryword_piechart_box {
  position: relative;
  min-height: 220px;
  .chart_wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 220px;
    height: 220px;
  }
  .alri_chart_legend {
    padding-left: 220px;
  }
}

.alri_chart_month {
  float: right;
  margin-top: 5px;
  font-size: 15px;
  font-weight: normal;
  color: $main_gray_normal;
}

.btn_more_list {
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  line-height: 2;
  font-size: 15px;
  background-color: #fafafa;
  border: solid 1px #e0e0e0;
  color: $main_gray_normal;
}

.ico_plus {
  margin-right: 5px;
}

.alri_vaccine {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  .background_box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  h2 {
    font-size: 40px;
    color: #fff;
    text-align: center;
    background: #b00100;
    padding: 20px;
    margin: 0;
  }
  .alri_review {
    box-sizing: border-box;
    padding: 20px;
    h3 {
      font-size: 20px;
    }
    .count_box {
      strong {
        font-size: 30px;
      }
    }
  }
  .content_box {
    position: relative;
    width: 600px;
    margin: 50px auto;
    background-color: #fff;
    z-index: 10;
    .content_box_wrap {
      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
    .total,
    .client {
      float: left;
      min-height: 150px;
    }
    .total {
      width: 200px;
      background: #a7b1c2;
      .alri_review {
        width: 100%;
      }
    }
    .client {
      width: 400px;
      &:after {
        content: "";
        display: block;
        clear: both;
      }
      .alri_review {
        width: 200px;
        float: left;
      }
      .alri_check_action {
        position: absolute;
        margin-top: 40px;
        right: 0;
        top: 44px;
        width: 260px;
        height: 100%;
        border: 1px solid #ccc;
        background: #1a2d40;
        color: #fff;
      }
    }
  }
  .alri_check_list {
    display: block;
    height: 300px;
    overflow-y: auto;
    background: #1a2d40;
    .fa {
      display: inline-block;
      font-size: 17px;
      color: #7ed321;
      margin-right: 10px;
    }
  }
  .btn {
    margin: 0 auto;
    display: none;
    width: 300px;
    z-index: 10;
    position: relative;
    background: #b00100;
    color: #fff;
  }
  .alri_date {
    font-size: 9px;
  }
  &.finish {
    .btn {
      display: block;
    }
    .client {
      background-image: url("http://cdnweb01.wikitree.co.kr/webdata/editor/201308/04/img_20130804110626_d2f09927.png");
      background-size: cover;
      background-position: 100% 100%;
      background-repeat: no-repeat;
    }
  }
}

.no_data_comment {
  display: none;
}
.is_no_data {
  .no_data_layout {
    display: block;
    & ~ .box_wrap,
    & ~ .box_result_comment,
    & ~ .alri_chart,
    & ~ .chart,
    & ~ .chart_wrap,
    & ~ .review_wrap,
    & ~ .alri_table,
    & ~ .alri_realnews,
    & ~ .list_ty1,
    & ~ .filter_box,
    & ~ .section_keyword_state,
    & ~ .btn_more_list,
    & ~ .result_bar {
      display: none;
    }
  }
}
.alri_spinner_line {
  position: absolute;
  top: 100%;
  left: 0;
  height: 5px;
  line-height: 0;
  font-size: 0;
  background: #3c8687;
}
.loading_page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: #f5f5f5;
  .loading_page_content {
    padding-top: 166px;
    .img {
      margin: 0 auto 18px;
      text-align: center;
    }
    .total_count {
      margin: 18px auto 23px;
      text-align: center;
      font-size: 25px;
      line-height: 1.2;
      color: #444444;
    }
    .alri_keyword {
      margin: 0 auto;
      text-align: center;
      font-size: 15px;
      line-height: 2;
      color: $main_gray_light;
    }
  }
}
/* keywords modal */
.alri_keywords_modal {
  width: 1000px;
  .modal-content {
    box-sizing: border-box;
    width: 100%;
    padding: 50px;
  }
  .modal-header {
    padding: 0;
    border-bottom: 0 none;
    text-align: left;
    .close {
      font-size: 40px;
      font-weight: 100;
      color: $main_gray_dark;
    }
  }
  .modal-title {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: normal;
    & + small {
      font-size: 15px;
      line-height: 2;
    }
    .date {
      display: inline-block;
      margin-left: 15px;
      font-size: 15px;
      line-height: 1.8;
      color: $main_gray_light;
      vertical-align: middle;
    }
  }
  .modal-body {
    padding: 0;
    background-color: #fff;
  }
}
.tags_wrap {
  margin-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  &:only-of-type {
    border-bottom: 0 none;
  }
  .tags_tit {
    padding: 10px 0;
    font-size: 20px;
    cursor: pointer;
    .tags_count {
      margin-left: 20px;
    }
    i {
      float: right;
      font-size: 28px;
      color: #979797;
    }
    &:after {
      display: block;
      clear: both;
      content: "";
    }
  }
  .fa-angle-down {
    display: none;
  }
  .fa-angle-up {
    display: block;
  }
  &.tags_off {
    .fa-angle-down {
      display: block;
    }
    .fa-angle-up {
      display: none;
    }
    .tags_list {
      display: none;
    }
  }
  .tags_list {
    padding: 10px 0;
  }
}

/* online real result */
.real_result {
  .ibox-content {
    &:first-of-type {
      padding-top: 20px;
    }
  }
}
.result_main_message {
  position: relative;
  padding-bottom: 20px;
  .text {
    position: relative;
    padding-left: 140px;
    padding-top: 38px;
    font-size: 25px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #444444;
    box-sizing: border-box;
    height: 104px;
    .state {
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 104px;
      height: 104px;
    }
  }
  .bottom_row {
    position: relative;
    margin-top: 13px;
    .description {
      font-size: 15px;
      letter-spacing: 0.1px;
      text-align: left;
      color: $main_gray_normal;
      display: inline-block;
      strong {
        font-family: SpoqaB;
      }
    }
    .btn_more {
      margin-left: 15px;
      display: inline-block;
      font-size: 15px;
      letter-spacing: 0.1px;
      text-align: left;
      color: #3c8687;
    }
  }
  .btn_more_arrow {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 100%;
    padding-left: 55px;
    margin-right: 10px;
    background: url("../images/btn_arrow_next.svg") no-repeat 100% 50%;
    background-size: 15px 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
}
.result_keyword_group {
  .result_keyword_list {
    position: relative;
    margin: 0;
    padding: 0 0 0 119px;
    dt,
    dd {
      display: inline-block;
      min-height: 30px;
      margin: 0;
      padding: 0;
    }
    dt {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 15px;
      letter-spacing: 0.1px;
      text-align: left;
      color: #444444;
      padding-left: 17px;
      &:before {
        position: absolute;
        left: 0;
        top: 6px;
        display: block;
        content: "";
        border: 1px solid transparent;
        border-radius: 50%;
        height: 7px;
        width: 7px;
      }
    }
    dd {
      font-size: 15px;
      letter-spacing: 0.1px;
      text-align: left;
    }
    &.danger {
      dt:before {
        background-color: #fd5e52;
      }
    }
    &.warning {
      dt:before {
        background-color: #f5a623;
      }
    }
    &.caution {
      dt:before {
        background-color: #7ed321;
      }
    }
  }
}
.total_count_group {
  border-top: 1px solid #e0e0e0;
  padding-top: 30px;
  .total_count_content {
    padding: 0 8px;
    .count_item {
      display: inline-block;
      margin-left: 40px;
      color: $main_gray_deep;
      font-size: 20px;
      &:first-of-type {
        margin-left: 0;
      }
      .count {
        margin-left: 8px;
      }
    }
  }
}
.real_head {
  width: calc(100% - #{$left_width});
  background-color: $emph_red;
  font-size: 15px;
  text-align: left;
  margin: 0 0 0 $left_width;
  box-sizing: border-box;
  padding: 27px 0 26px 40px;
  &_message {
    /* new style */
    display: inline-block;
    vertical-align: top;
    .result_head_message {
      line-height: 35px;
    }
    .btn_restart {
      padding: 0;
      margin-left: 16px;
      border: 0 none;
      background-color: transparent;
      color: $main_green_deep;
      font-weight: bold;
      line-height: 27px;
    }
    &:after {
      display: block;
      clear: both;
      content: "";
    }
  }
  .result_head_message,
  .btn_restart {
    color: #fff;
    display: inline-block;
  }
  .btn_restart {
    margin-left: 15px;
    padding: 0;
    font-family: "SpoqaB" !important;
    border: 0 none;
    background-color: $emph_red;
  }
}
.feed_view_modal {
  width: 1000px;
  .modal-content {
    background-color: transparent !important;
  }
  .close {
    font-size: 40px;
    font-weight: 100;
    color: $main_gray_dark;
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 3;
    cursor: pointer;
  }
  .modal-body {
    padding: 50px;
    background-color: #fff;
  }
  .alri_feed_view {
    width: 900px;
    font-size: 14px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    .col_left {
      float: left;
      width: 175px;
    }
    .col_right {
      width: 725px;
      float: left;
      overflow-y: scroll;
      h3 {
        line-height: 30px;
        font-size: 20px;
        text-align: left;
        font-weight: 600;
        color: $main_gray_deep;
        & ~ .text-muted {
          display: block;
          font-size: 13px;
          margin-top: 10px;
          color: $main_gray_light;
          margin-bottom: 20px;
        }
      }
      .text-muted {
      }
    }
    .feed_content {
      display: block;
      li {
        margin-bottom: 30px;
        h5 {
          height: 30px;
          line-height: 30px;
          font-size: 15px;
          margin-bottom: 5px;
          font-family: "SpoqaB";
        }
        & > div {
          font-size: 15px;
          line-height: 2em;
        }
        & > em {
          font-family: "SpoqaB";
        }
      }
    }
    .form-control {
      border-radius: 2px;
      background-color: #fafafa;
      border: solid 1px #e0e0e0;
      resize: none;
      padding: 20px;
    }
    .feed-element {
      & > h2 {
        margin: 0 0 50px;
      }
    }
  }
  .btn-ok,
  .btn-primary {
    background-color: #3c8687;
  }
  .btn-modify {
    background-color: #4a90e2;
  }
  .btn-delete {
    background-color: #fd5e52;
  }
  .btn_group {
    margin-top: 15px;
    margin-bottom: 0;
    text-align: right;
    input {
      color: #fff;
      border-radius: 2px;
      margin-left: 12px;
    }
  }
}
.um_newstar_l {
  position: relative;
  width: 123px;
  height: 23px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/star_img_out.png")
    0 0 no-repeat;
  background-size: cover;
  overflow: hidden;
  .um_newstar_c {
    position: absolute;
    left: 0;
    height: 100%;
    text-indent: -1000%;
    overflow: hidden;
    background: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/star_img_in.png")
      0 0 no-repeat;
  }
}
.um_newbar_l {
  position: relative;
  width: 125px;
  height: 5px;
  background: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/bar_img_out.png")
    0 0 no-repeat;
  background-size: cover;
  overflow: hidden;
  .um_newbar_c {
    position: absolute;
    left: 0;
    height: 100%;
    text-indent: -1000%;
    overflow: hidden;
    background: url("https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/bar_img_in.png")
      0 0 no-repeat;
  }
}
.graph_wrap {
  display: block;
  li {
    display: block;
    margin-bottom: 15px;
    .name {
      display: block;
      height: 30px;
      line-height: 30px;
      font-weight: normal;
      font-size: 15px;
      text-align: left;
      color: $main_gray_deep;
      margin: 0 0 5px 0;
    }
    .text {
      line-height: 30px;
      font-weight: normal;
      font-size: 15px;
    }
  }
  .no_graph {
    .name {
      font-family: "SpoqaB";
      margin-bottom: 0;
    }
  }
  .total {
    margin-bottom: 30px;
  }
}
.modal-midle-content {
  width: 600px;
  padding: 50px;
  box-sizing: content-box;
  margin: 0 auto;
  .btn_group {
    margin-top: 30px;
    text-align: center;
  }
}
.sub_madal_content {
  text-align: center;
  font-size: 15px;
  line-height: 2;
  text-align: center;
  color: $main_gray_deep;
  strong {
    font-family: "SpoqaB";
  }
}
.ibox-content-notbg {
  position: relative;
  .button-next,
  .button-prev {
    display: block;
    position: absolute;
    top: 50%;
    width: 18px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: 0 0;
    overflow: hidden;
    text-indent: -1000px;
    &.swiper-button-disabled {
      opacity: 0.4;
      &:hover {
        cursor: default;
      }
    }
  }
  .button-next {
    right: 0;
    background: url("../images/btn_arrow_next.svg");
  }
  .button-prev {
    left: 0;
    background: url("../images/btn_arrow_prev.svg");
  }
  & > .button-next,
  & > .button-prev {
    display: none;
  }
}
.rank_list {
  width: 300px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  background: #fff;
  padding-bottom: 30px;
  .rl_head {
    width: 260px;
    height: 68px;
    padding-top: 30px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: $main_gray_deep;
    border-bottom: 1px solid #e0e0e0;
    margin: 0 auto 20px;
  }
  .rl_body_group {
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: scroll;
    width: 298px;
    height: 440px;
    padding-left: 19px;
  }
  .rl_tb {
    box-sizing: border-box;
    width: 100%;
    height: 440px;
    padding-right: 19px;
  }
  .rl_col {
    col {
      &:first-of-type {
        width: 40px;
      }
      &:nth-of-type(2) {
        width: 150px;
      }
      &:last-of-type {
        width: 60px;
      }
    }
  }
  .rl_tb_body {
    display: block;
    width: 100%;
  }
  .rlb_tb_row {
    td,
    th {
      height: 45px;
    }
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    .number {
      box-sizing: border-box;
      display: block;
      width: 25px;
      height: 25px;
      margin-right: 15px;
      line-height: 25px;
      border-radius: 2px;
      border: solid 1px #979797;
      text-align: center;
      font-size: 10px;
      &.new {
        color: #7ed321;
        border: solid 1px #7ed321;
      }
    }
    .text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 150px;
      height: 25px;
      line-height: 25px;
      letter-spacing: 0.1px;
      text-align: left;
      white-space: nowrap;
    }
    .count {
      display: block;
      width: 60px;
      height: 25px;
      line-height: 25px;
      font-size: 10px;
      letter-spacing: 0.1px;
      text-align: right;
      color: $main_gray_light;
      overflow: hidden;
    }
  }
}

.ranklist_group {
  width: 920px;
  height: 560px;
  margin: 0 auto;
  overflow: hidden;
}
.ibox-data-info {
  margin-top: 15px;
  line-height: 30px;
  margin-bottom: 5px;
  letter-spacing: 0.1px;
  text-align: right;
  color: $main_gray_light;
}
@import "./alri/swiper.scss";

/* ie11 swiper translate bug */
.ranklist_wrap {
  opacity: 0.99;
}

.section_keyword_state {
  display: block;
  margin-top: 30px;
  border-bottom: 1px solid #e0e0e0;
  &:first-of-type {
    margin-top: 10px;
  }
  .title {
    width: 100%;
    margin-bottom: 15px;
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    .badge_danger,
    .badge_warning,
    .badge_caution {
      & + .keyword {
        margin-left: 5px;
      }
    }
  }
  .keyword {
    font-size: 18px;
    color: $main_gray_deep;
    line-height: 20px;
    vertical-align: top;
  }
  .info {
    float: right;
    font-size: 15px;
    color: $main_gray_light;
    line-height: 20px;
    vertical-align: top;
  }
  .date {
    margin-left: 15px;
  }
  .title_text {
    float: left;
    overflow: hidden;
    display: block;
    width: 74%;
    height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    word-wrap: normal;
    &.lg_size {
      font-size: 18px;
      & ~ .count_number {
        font-size: 18px;
      }
    }
  }
  .count_number {
    display: inline-block;
    margin-left: 10px;
  }
  .content {
    display: block;
    margin-bottom: 15px;
    font-size: 15px;
    color: $main_gray_normal;
    line-height: 30px;
    word-wrap: break-word;
    word-break: break-all;
  }
  a {
    &:hover {
      &.content,
      .content {
        @include hover_underline_style();
      }
    }
  }
  & + .btn_more_list {
    margin-top: 20px;
  }
}

/* tab style - news type */
.alri_tab_news_type {
  position: relative;
  width: 100%;
  height: 64px;
  z-index: 1;
  .tab_item {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding: 20px 30px 12px;
    margin-left: 6px;
    font-size: 20px;
    line-height: 30px;
    color: $main_gray_very_light;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    text-align: center;
    overflow: hidden;
    &.on {
      color: $main_green_deep;
      border-bottom: 1px solid #fff;
      font-weight: 600;
    }
    &:hover {
      text-decoration: none;
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
  & + .ibox-content {
    margin-top: -1px;
  }
}
.alri_tab_content {
  display: none;
  &.on {
    display: block;
  }
  & ~ .alri_tab_content {
    margin-top: 0;
  }
}

/* 소셜 키워드 상세 */
.social_content_wrapper {
  padding-top: 30px;
  .alri_keyword_title {
    margin-bottom: 40px;
  }

  .section_keyword_state {
    margin-top: 40px;
  }
}

/* tab style - tab social type  */
.alri_tab_social_type {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  .tab_item {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 20%;
    padding: 18px 20px;
    font-size: 15px;
    line-height: 20px;
    color: $main_gray_light;
    background-color: #fff;
    text-align: center;
    float: left;
    &.on {
      color: $main_green_deep;
    }
    &:hover {
      text-decoration: none;
    }
  }
  & + .ibox-content {
    padding: 0;
    border: 0 none;
  }
}
.social_content_box {
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 524px;
  .no_data_layout {
    width: 940px;
    margin-top: 40px;
  }
}

.jp_h3_title {
  padding: 15px;
  h3 {
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: 0.1px;
    text-align: center;
    color: #444;
  }
  .text-l {
    text-align: left;
  }
}

.content_guide {
  padding: 0 15px 30px;
  .jp_h3_title {
    padding-bottom: 0;
  }
}
.content_guide_text {
  position: relative;
  font-size: 15px;
  line-height: 2;
  letter-spacing: 0.1px;
  color: #666;
  &:after {
    display: block;
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 40px;
    width: 61px;
    height: 44px;
    color: #636363;
    background: url("../images/page-1.svg") 0 0 no-repeat;
    background-size: cover;
  }
}

/* 못다 한 이야기 */
.ibox-title {
  .desc {
    margin-top: 0;
  }
}
.filter_box {
  position: relative;
  width: 100%;
  padding-right: 150px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  .current_category {
    line-height: 34px;
    color: $main_green_deep;
    font-size: 15px;
    min-height: 34px;
  }
  .btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  & ~ .section_keyword_state {
    margin-top: 40px;
  }
}

.filter_content_wrapper {
  &:after {
    display: block;
    content: "";
    clear: both;
  }
  .filter_list_wrap {
    width: calc(100% / 3);
    float: left;
    &:last-of-type {
      .filter_item {
        padding-left: 113px;
      }
    }
  }
  .filter_list_tit {
    padding: 11px 0 15px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 20px;
    color: $main_gray_deep;
    text-align: center;
    font-weight: normal;
  }
  .filter_list_body {
    color: $main_gray_normal;
  }
  .form-group {
    padding-top: 10px;
  }
  .filter_item {
    padding: 13px 0 12px 43px;
    &.all {
      padding-top: 18px;
      border-bottom: 1px solid #e0e0e0;
      background-color: #f9f9f9;
      label {
        font-weight: bold;
      }
    }
    label {
      font-weight: normal;
    }
  }
  .icheckbox_square-green {
    margin-right: 10px;
    background-color: #fff;
  }
  & + .btn_group {
    margin-bottom: 0;
  }
}
.read_more {
  display: inline-block;
  color: $main_green_deep;
  font-weight: bold;
}
.page_nav_tab {
  padding-top: 37px;
  margin-bottom: 30px;
  .nav_list {
    border-bottom: 2px solid #e0e0e0;
  }
  .nav_item {
    display: inline-block;
    margin-left: 46px;
    margin-bottom: -2px;
    font-size: 25px;
    border-bottom: 2px solid transparent;
    a,
    span,
    button {
      display: inline-block;
      padding-bottom: 6px;
      color: $main_gray_light;
    }
    &.active {
      border-bottom-color: $main_green_deep;
      color: $main_gray_deep;
      font-weight: 700;
      a,
      button {
        color: $main_gray_deep;
        font-weight: 700;
      }
    }
    &:first-of-type {
      margin-left: 0;
    }
  }
}
.tooltip {
  .tooltip-inner {
    min-width: 222px;
    padding: 5px;
  }
}

/* 기간설정 */
.date-setting-wrap {
  padding: 15px 30px;
  .form-group {
    margin-bottom: 0;
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  .control-label {
    float: left;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 2;
  }
}
.date_picker_group {
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
.date_picker_box {
  float: right;
  width: 300px;
  height: 40px;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  border-color: #e0e0e0;
  color: #444;
  font-size: 15px;
  .input-group-addon,
  .form-control {
    height: inherit;
    border-color: inherit;
  }
  .form-control {
    font-size: 14px;
  }
  &.date {
    .input-group-addon,
    .input-group-addon i {
      cursor: default;
    }
    .icon_calendar {
      background-color: #fff;
      border-left: 1px solid #e0e0e0;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  }
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}

/* blind */
.blind_content_count {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}
.blind_count_list {
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
.blind_count_item {
  display: inline-block;
  margin-left: 40px;
  color: $main_gray_normal;
  font-size: 20px;
  .count {
    display: inline-block;
    margin-left: 5px;
  }
  &:first-of-type {
    margin-left: 0;
  }
}

.blind_content_link {
  display: block;
  &:hover {
    cursor: pointer;
  }
  .title {
    height: 21px;
    .title_text {
      width: auto;
      height: auto;
      max-width: 94%;
    }
  }
  .info {
    float: none;
  }
}

.blind_content_list {
  .blind_content,
  .blind_comment {
    position: relative;
    padding-left: 37px;
    margin-top: 15px;
    line-height: 20px;
    box-sizing: border-box;
    .title_text {
    }
    .badge_dark_blue,
    .badge_deep_green {
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  .blind_content {
    .title_text {
      width: 100%;
    }
  }
  .blind_comment {
    .title_text {
      width: auto;
      max-width: 86%;
    }
    .date {
      color: $main_gray_light;
    }
  }
}
.section_keyword_state {
  .blind_content_link {
    &:hover {
      .title_text {
        @include hover_underline_style();
      }
      .content,
      .blind_content_list .title_text {
        text-decoration: none;
      }
    }
  }
}

.blind_modal {
  .modal-header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    .info {
      margin-top: 20px;
      color: $main_gray_light;
    }
    .date {
      margin-left: 15px;
    }
  }
  .modal-title {
    width: 95%;
    word-break: break-all;
    word-wrap: break-word;
  }
  .modal-body {
    height: auto;
  }
  .content {
    margin-bottom: 0;
  }
  .blind_content_list {
    .social_content_box {
      height: auto;
      max-height: 479px;
    }
    .blind_content,
    .blind_comment {
      padding-bottom: 20px;
    }
    .badge_dark_blue,
    .badge_deep_green {
      top: 3px;
    }
    .title_text {
      float: none;
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
      line-height: 25px;
      white-space: normal;
      word-wrap: break-word;
    }
    .date {
      display: inline-block;
      margin: 10px 0 0;
    }
  }
  .inner_wrap,
  .blind_content {
    margin-top: 30px;
  }
  .inner_wrap {
    padding-right: 10px;
  }
  .blind_content {
    border-bottom: 1px solid #e0e0e0;
  }
}

/*190304 추가*/
a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.ibox-content {
  h3,
  h4 {
    font-weight: 600;
  }
}

/*190314 추가*/
.row page_nav_tab {
  position: relative;
}
.page_main_title {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.jp_modal_link {
  cursor: pointer;
}
.alri_modal_a {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.alri_modal_b {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.alri_modal_no_approved {
  color: #fff;
  border-radius: 2px;
  margin-left: 12px;
  &:hover {
    color: #fff;
  }
}
@keyframes bounce_spinner {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0.1;
    transform: translateY(-20px);
  }
}

.jply_checkbox_box {
  position: relative;
  display: inline-block;
  height: 24px;
  padding-left: 32px;
  box-sizing: border-box;
  .jply_checkbox_item {
    opacity: 0;
    filter: Alpha(opacity=0);
    position: absolute;
    left: 0;
    top: 0;
    &:checked {
      & ~ .checkbox_icon {
        background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/9/ic_checkbox_checked.svg");
      }
    }
  }
  .checkbox_icon {
    overflow: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
    background-image: url("https://jpassets.jobplanet.co.kr/production/uploads/material/media/10/ic_checkbox_unchecked.svg");
    background-repeat: no-repeat;
    background-position: 0 0px;
  }
  .checkbox_text {
    font-family: "SpoqaR", "돋움", dotum, Arial, sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: #323438;
  }
}

.win {
  .jply_ip_ty1,
  .jply_btn_select {
    padding: 11px 16px 12px 16px;
  }
}

#page-wrapper {
  width: 1051px !important;
}

.jpspa {
  .ibox-title {
    background: #fff;
    padding: 32px;
    & > h1 {
      margin: 0;
      height: 36px;
      font-size: 24px;
      font-weight: bold;
      color: #323438;
      float: left;
    }
  }
  .ibox-content {
    border: none;
  }
  .default_company {
    height: 48px;
    line-height: 48px;
    border-radius: 3px;
    background-color: #f3f3f4;
    font-size: 15px;
    color: #323438;
  }
  .form-group,
  .default_company {
    box-sizing: border-box;
    width: 240px;
    display: inline-block;
  }
  .filter_wrap {
    border: none;
    text-align: center;
    .etc1 {
      padding: 0 8px;
      display: inline-block;
      height: 22px;
      font-size: 15px;
      text-align: center;
      color: #85878c;
    }
    .jply_btn_md {
      margin-left: 16px;
    }
    .form-group {
      margin-bottom: 0;
      vertical-align: top;
      .jply_ip_label {
        display: none;
      }
    }
  }
  .ty_default {
    margin-left: 8px;
  }
}
table.partners_table {
  thead {
    th {
      height: 40px;
      background-color: #f3f3f4;
      padding: 0;
      vertical-align: middle;
      font-weight: normal;
    }
  }
  td {
    height: 40px;
  }
}
.jpspa {
  h5 {
    margin: 0;
    font-size: 20px;
    color: #323438;
  }
  .feed-activity-list {
  }
  .feed-element {
    .media-body {
      font-size: 13px;
    }
  }
  .jp_title h2 {
    margin: 0 0 10px;
    font-family: "SpoqaB", "돋움", dotum, Arial, sans-serif !important;
    font-size: 20px;
  }
}
#visitor_statistics {
  padding: 0;
  .row {
    padding: 40px;
    border-top: solid 1px #e5e6e9;
  }
  .btn-loading {
    display: none;
  }
  table.partners_table {
    table-layout: fixed;
    /*table-layout: auto;*/
    border-spacing: 0;
    border-collapse: collapse;
    .col_w1 {
      width: 40px;
    }
    thead tr {
      position: absolute;
      top: 0;
    }
    tr {
      table-layout: fixed;
      width: 100%;
      display: inline-table;
    }
    td,
    th {
      border-top: none;
      border-bottom: none;
      box-sizing: border-box;
      padding: 0;
      vertical-align: middle;
    }
    .first_td {
      width: 40px;
    }
  }
  .inner-ibox-content {
    height: 320px;
    padding-bottom: 0;
    margin-bottom: 15px;
    position: relative;
    padding-top: 40px;
    box-sizing: border-box;
  }
  .scroll_table {
    max-height: 280px;
    overflow: auto;
    overflow-x: hidden;
  }
  .bar-in-table {
    background-color: #00c362;
    display: inline;
    float: left;
    height: 1.3em;
    min-width: 1px;
    border-radius: 1px;
    margin-right: 8px;
  }
  .competitor_wrap {
    & > .col-lg-12 {
      margin-top: 40px;
    }
    & > .col-lg-12:first-child {
      margin-top: 0;
    }
  }
}
.visit_compare_piecahrt,
.inner-ibox-content {
  position: relative;
  margin-top: 16px;
  .sub_text {
    z-index: 6;
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-bottom: 3px;
    font-size: 11px;
    font-weight: normal;
  }
}
.review_c2,
.review_c3 {
  background-color: #f8ac59 !important;
  font-size: 0.9em;
  padding-top: 6px;
}
.review_c1 {
  background-color: #ed5565 !important;
}
