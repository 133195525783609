@import "_val";

/* Button */
.jply_btn_xs {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  min-width:28px;
  height: 28px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  font-family: 'Roboto', 'Noto Sans Korean' !important;
  font-size: 12px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  i[class^="jp-"] + .icon_text,
  i[class*=" jp-"] + .icon_text,
  .icon_text + i[class^="jp-"],
  .icon_text + i[class*=" jp-"] {
    margin-left: 4px;
  }
}
.jply_btn_sm {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  min-width:46px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  font-family: 'Roboto', 'Noto Sans Korean' !important;
  font-size: 14px;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  i[class^="jp-"] + .icon_text,
  i[class*=" jp-"] + .icon_text,
  .icon_text + i[class^="jp-"],
  .icon_text + i[class*=" jp-"] {
    margin-left: 4px;
  }
}
.jply_btn_md {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 48px;
  min-width:54px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
  font-family: 'Roboto', 'Noto Sans Korean' !important;
  font-size: 14px;
  line-height: 21px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  i[class^="jp-"] + .icon_text,
  i[class*=" jp-"] + .icon_text,
  .icon_text + i[class^="jp-"],
  .icon_text + i[class*=" jp-"] {
    margin-left: 4px;
  }
}
.jply_btn_lg {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  height: 56px;
  min-width:64px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  font-family: 'Roboto', 'Noto Sans Korean' !important;
  font-size: 16px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  i[class^="jp-"] + .icon_text,
  i[class*=" jp-"] + .icon_text,
  .icon_text + i[class^="jp-"],
  .icon_text + i[class*=" jp-"] {
    margin-left: 6px;
  }
}
.jply_btn_xl {
  display: inline-block;
  box-sizing: border-box;
  width: auto;
  min-width:78px;
  height: 64px;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 19px;
  padding-bottom: 19px;
  text-align: center;
  font-family: 'Roboto', 'Noto Sans Korean' !important;
  font-size: 20px;
  line-height: 26px;
  -webkit-font-smoothing: antialiased;
  vertical-align: top;
  i[class^="jp-"] + .icon_text,
  i[class*=" jp-"] + .icon_text,
  .icon_text + i[class^="jp-"],
  .icon_text + i[class*=" jp-"] {
    margin-left: 6px;
  }
}

.jply_disabled {
  opacity: 0.4;
  &:hover,
  &:focus {
    cursor: not-allowed;
  }
}
.ty_default,
.ty_default_solid,
.ty_solid,
.ty_line,
.ty_light,
.ty_text {
  &:disabled {
    opacity: 0.4;
    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
}

.block {
  display: block;
  width: 100%;
}

.ty_default {
  background-color: transparent;
  border: 1px solid $gray200;
  border-radius: 5px;
  color: $gray600;
  &:hover,
  &:focus {
    background-color: $gray50;
    color: $gray600;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
.ty_default_solid {
  background-color: $gray50;
  border: 1px solid $gray50;
  border-radius: 5px;
  color: $gray500;
  &:hover,
  &:focus {
    background-color:$gray100;
    color: $gray500;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color:$gray50;
    }
  }
}
.ty_solid {
  background-color: $green500;
  border: 1px solid $green500;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  &:hover,
  &:focus {
    background-color: $green600;
    border: 1px solid $green600;
    color: #fff;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: $green500;
    }
  }
}
.ty_line {
  background-color: #fff;
  border: 1px solid $green500;
  border-radius: 5px;
  color: $green500;
  &:hover,
  &:focus {
    background-color: $green50;
    color: $green500;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
.ty_light {
  background-color: $green50;
  border: 1px solid transparent;
  border-radius: 5px;
  color: $green600;
  &:hover,
  &:focus {
    background-color: $green100;
    color: $green600;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: $green50;
    }
  }
}
.ty_text {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  color: $gray600;
  &:hover,
  &:focus {
    border-color:$gray50;
    background-color: $gray50;
    color: $gray600;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      border-color:transparent;
      background-color: transparent;
    }
  }
}
.ty_gray50 {
  background-color: $gray50;
  border-radius: 5px;
  border: 1px solid $gray50;
  font-weight: bold;
  color: $gray600;
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $gray100;
    border: 1px solid $gray100;
    color: $gray600;
  }
}

.ty_gray100 {
  background-color: $gray50;
  border-radius: 5px;
  border: 1px solid $gray50;
  font-weight: bold;
  color: $gray500;
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: $gray100;
    border: 1px solid $gray100;
    color: $gray500;
  }

}

.ty_fit{
  font-weight: 400;
  i {
    margin-right: 5px;
    color: $red400;
  }
  &.is_disabled{
    color: $gray100 !important;
    background: $gray30 !important;
    border-color: $gray30 !important;
    opacity: 1;
    .jf_button__icon{
      color: $gray100 !important;
    }
  }
}

/* jply Button Group */
.jply_button_group {
  text-align: center;
  font-size: 0;
  .jply_btn_sm {
    margin: 0 4px;
  }
  .jply_btn_md,
  .jply_btn_lg,
  .jply_btn_xl {
    margin: 0 8px;
  }
}

/* 버튼 내부에 사용되는 left 정렬된 아이콘 */
.ico_left_linkout,
.ico_left_modify {
  padding-left: 43px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: 16px 12px;
}
.ico_left_linkout {
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/80/ic_linkout_gray500_sm.svg');
}
.ico_left_modify {
  background-image: url('https://jpassets.jobplanet.co.kr/production/uploads/material/media/100/ic_edit_gray500_sm.svg');
}

/* 버튼 새로운 컬러 추가 ty_line */
.ty_line_blue {
  background-color: #fff;
  border: 1px solid $blue500;
  border-radius: 5px;
  color: $blue500;
  &:hover,
  &:focus {
    background-color: $blue50;
    color: $blue500;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.ty_line_red {
  background-color: #fff;
  border: 1px solid $red500;
  border-radius: 5px;
  color: $red500;
  &:hover,
  &:focus {
    background-color: $red50;
    color: $red500;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.ty_line_orange {
  background-color: #fff;
  border: 1px solid $orange500;
  border-radius: 5px;
  color: $orange500;
  &:hover,
  &:focus {
    background-color: $orange50;
    color: $orange500;
    text-decoration: none;
  }
  &.jply_disabled,
  &:disabled {
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

/*jf-button 2022.11.14*/
.jf_button{
  $this:&;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  &.size_large{
    width: auto;
    height: 56px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 8px;
    #{$this}__box{
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      margin-top: 2px;
    }
    #{$this}__icon {
      top: -2px;
      font-size: 16px;
    }
    &.icon_both {
      #{$this}__txt{margin: 0 2px;}
    }
    &.icon_left {
      padding: 0 28px 0 20px;
      #{$this}__txt{margin-left: 2px;}
    }
    &.icon_right {
      padding: 0 20px 0 28px;
      #{$this}__txt{margin-right: 2px;}
    }
  }
  &.size_medium{
    width: auto;
    height: 48px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    #{$this}__box{
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      margin-top: -2px;
    }
    #{$this}__icon {
      font-size: 16px;
    }
    &.icon_both {
      #{$this}__txt{margin: 0 2px;}
    }
    &.icon_left {
      padding: 0 24px 0 16px;
      #{$this}__txt{margin-left: 2px;}
    }
    &.icon_right {
      padding: 0 16px 0 24px;
      #{$this}__txt{margin-right: 2px;}
    }
  }
  &.size_small{
    height: 40px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 6px;
    #{$this}__box{
      display: inline-flex;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      margin-top: -2px;
    }
    #{$this}__icon {
      font-size: 14px;
    }
    &.icon_both {
      #{$this}__txt{margin: 0 2px;}
    }
    &.icon_left {
      padding: 0 16px 0 12px;
      #{$this}__txt{margin-left: 2px;}
    }
    &.icon_right {
      padding: 0 12px 0 16px;
      #{$this}__txt{margin-right: 2px;}
    }
  }
  &.size_xsmall{
    height: 28px;
    padding: 0 8px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
    #{$this}__box{
      display: inline-flex;
      width: 16px;
      height: 16px;
      justify-content: center;
      align-items: center;
      margin-top: -1px;
    }
    #{$this}__icon {
      font-size: 12px;
    }
    &.icon_both {
      #{$this}__txt{margin: 0 2px;}
    }
    &.icon_left {
      padding: 0 12px 0 8px;
    }
    &.icon_right {
      padding: 0 8px 0 12px;
    }
  }
  &.type_primary {
    color: #fff;
    font-weight: 700;
    border: 1px solid $green500;
    background: $green500;
    #{$this}__icon{
      color: #fff;
    }
    &:hover {
      border: 1px solid $green600;
      background: $green600;
    }
    &:active {
      border: 1px solid $green700;
      background: $green700;
    }
    &:disabled {
      border: 1px solid $green100;
      background: $green100;
      cursor: default;
    }
  }
  &.type_secondary {
    color: $gray500;
    border: 1px solid $gray50;
    background: $gray50;
    #{$this}__icon {
      color: $gray500;
    }
    &:hover {
      color: $gray600;
      border: 1px solid $gray100;
      background: $gray100;
      #{$this}__icon {
        color: $gray600;
      }
    }
    &:active {
      color: $gray700;
      border: 1px solid $gray200;
      background: $gray200;
      #{$this}__icon {
        color: $gray700;
      }
    }
    &:disabled {
      color: $gray100;
      border: 1px solid $gray30;
      background: $gray30;
      cursor: default;
      #{$this}__icon {
        color: $gray100;
      }
    }
  }
  &.type_tertiary {
    color: $green500;
    border: 1px solid $green500;
    background: #fff;
    #{$this}__icon {
      color: $green500;
    }
    &:hover {
      color: $green600;
      border: 1px solid $green600;
      background: $green30;
      #{$this}__icon {
        color: $green600;
      }
    }
    &:active {
      color: $green700;
      border: 1px solid $green700;
      background: $green50;
      #{$this}__icon {
        color: $green700;
      }
    }
    &:disabled {
      color: $green100;
      border: 1px solid $green100;
      background: #fff;
      cursor: default;
      #{$this}__icon {
        color: $green100;
      }
    }
  }
  &.type_ghost {
    color: $green500;
    font-weight: 700;
    border: 1px solid #fff;
    background-color: transparent;
    #{$this}__icon {
      color: $green500;
    }
    &:hover {
      color: $green600;
      border: 1px solid $green30;
      background: $green30;
      #{$this}__icon {
        color: $green600;
      }
    }
    &:active {
      color: $green700;
      border: 1px solid $green50;
      background: $green50;
      #{$this}__icon {
        color: $green700;
      }
    }
    &:disabled {
      color: $green100;
      border: 1px solid #fff;
      background: #fff;
      cursor: default;
      #{$this}__icon {
        color: $green100;
      }
    }
  }
  &.type_underline {
    color: $green500;
    font-weight: 700;
    text-decoration: underline;
    border: 1px solid #fff;
    background-color: transparent;
    &:hover {
      color: $green600;
    }
    &:active {
      color: $green700;
    }
    &:disabled {
      color: $green100;
      cursor: default;
      #{$this}__icon {
        color: $green100;
      }
    }
  }
  &.type_alert {
    color: #fff;
    font-weight: 700;
    border: 1px solid $red500;
    background: $red500;
    #{$this}__icon {
      color: #fff;
    }
    &:hover {
      border: 1px solid $red600;
      background: $red600;
    }
    &:active {
      border: 1px solid $red700;
      background: $red700;
    }
    &:disabled {
      color: #fff;
      border: 1px solid $red100;
      background: $red100;
      cursor: default;
      #{$this}__icon {
        color: #fff;
      }
    }
  }
  &__icon{
    position: relative;
    top: 1px;
  }
}