.hr_insight_outside{
  width: 1020px !important;
  $main_gray_dark: #333333;
  $main_gray_deep: #444444;
  $main_gray_normal: #666666;
  $main_gray_light: #999999;
  $main_gray_very_light: #d8d8d8;
  $main_green_light: #A9DBA8;
  $main_green_normal: #7BBC9C;
  $main_green_deep: #3C8787;
  $main_green_dark: #074061;
  $emph_red: #FD5E52;
  $emph_orange: #F5A623;
  $emph_light_green: #7ED321;
  $emph_blue: #4A90E2;
  $emph_deep_green: #3C8787;
  $alri_header_height: 79px;
  $left_width: 310px;
  a{
    color: #444;
    &:hover{
      color: #666;
      text-decoration: none;
    }
  }


  .ranklist_wrap {
    opacity: 0.99;
  }
  .rl_head {
    width: 260px;
    height: 68px;
    padding-top: 30px;
    box-sizing: border-box;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    letter-spacing: 0.1px;
    text-align: center;
    color: #444;
    border-bottom: 1px solid #e0e0e0;
    margin: 0 auto 20px;
  }

  .rl_body_group {
    box-sizing: border-box;
    min-width: 294px;
    .jp_h3_title {
      padding-top: 0px;
      padding-bottom: 25px;
    }
  }

  .table_type_0 {
    width: 100%;
    table-layout: fixed;
    .rl_col {
      col {
        &:first-of-type {
          width: 40px;
        }
        &:last-of-type {
          width: 60px;
        }
      }
    }
  }

  .table_type_1 {
    width: 100%;
    table-layout: fixed;
    .rl_col {
      col {
        &:first-of-type {
          width: 40px;
        }
      }
    }
  }

  .rl_tb {
    width: 100%;
    height: 440px;
    padding-right: 19px;
  }

  .rl_tb_body {
    display: block;
    width: 100%;
  }

  .rlb_tb_row {
    th {
      height: 60px;
    }
    td {
      height: 45px;
    }
    .number {
      box-sizing: border-box;
      display: block;
      width: 25px;
      height: 25px;
      margin-right: 15px;
      line-height: 25px;
      border-radius: 2px;
      border: solid 1px #979797;
      text-align: center;
      font-size: 10px;
      &.new {
        color: #7ed321;
        border: solid 1px #7ed321;
      }
    }
    .text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 25px;
      line-height: 25px;
      letter-spacing: 0.1px;
      text-align: left;
      white-space: nowrap;
    }
    .count {
      display: block;
      width: 60px;
      height: 25px;
      line-height: 25px;
      font-size: 15px;
      letter-spacing: 0.1px;
      text-align: right;
      color: #999999;
      overflow: hidden;
      position: relative;
      padding-right: 20px;
      &.won {
        padding-right: 0;
        font-size: 12px;
      }
    }
    .fa {
      font-size: 20px;
      position: absolute;
      top: 1px;
      right: 0;
    }
    .fa-angle-down {
      color: #fd5e52;
    }
    .fa-angle-up {
      color: #4a90e2;
    }
    .fa-plus {
      color: #8ebebf;
      font-size: 14px;
      top: 5px;
      right: 1px;
    }
    .fa-minus {
      color: #666;
      font-size: 14px;
      top: 5px;
      right: 1px;
    }
  }

  .rlb_tb_row_head {
    td {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 20px;
    }
    &+.rlb_tb_row {
      td {
        padding-top: 20px;
        height: 65px;
      }
    }
  }

  .table_type_0 .active,
  .rlb_tb_row_head {
    .number {
      color: #3c8687;
      border-radius: 2px;
      background-color: rgba(60, 134, 135, 0.1);
      border: solid 1px #3c8687;
      font-family: 'SpoqaB', '돋움', dotum, Arial, sans-serif !important;
      &.disable {
        color: #5f5f5f;
        background-color: rgba(95, 95, 95, 0.1);
        border: solid 1px #5f5f5f;
      }
    }
    .text {
      font-family: 'SpoqaB', '돋움', dotum, Arial, sans-serif !important;
    }
  }

  .jp_h3_title {
    padding: 15px;
    h3 {
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: 0.1px;
      text-align: center;
      color: #444;
    }
    .text-l {
      text-align: left;
    }
  }

  .ibox-content {
    padding-top: 25px;
    border: none;
  }

  .row_type_a {
    background: #fff;
    padding: 15px 20px 20px 20px;
    margin: 0;
  }

  #page-wrapper {
    max-width: 1089px;
  }

  .jp_from_r {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  /*글로벌용*/

  .jp_title {
    position: relative;
    h2 {
      margin: 0 0 10px;
      font-family: 'SpoqaB', '돋움', dotum, Arial, sans-serif !important;
      font-size: 20px;
      &>.company_name {
        display: inline-block;
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: top;
      }
      &>span {
        font-family: 'SpoqaR', '돋움', dotum, Arial, sans-serif !important;
        vertical-align: top;
      }
    }
    .description {
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.1px;
      text-align: left;
      color: #888;
      margin-top: 15px;
      line-height: 30px;
      white-space: pre-line;
    }
  }

  .p_a_rb {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .page-heading {
    padding-left: 0;
    padding-bottom: 53px;
  }

  .page-heading-wrap {
    position: relative;
  }

  .btn_in {
    position: relative;
  }

  .date-setting {
    position: absolute;
    top: 20px;
    right: 5px;
    &.show {
      .date-setting-box {
        display: block;
      }
    }
  }

  .btn-top-dataset {
    width: 300px;
    box-sizing: border-box;
    position: relative;
    padding: 18px 40px 18px 20px;
    text-align: left;
    border-color: #e0e0e0;
    color: #444;
    font-size: 15px;
    .fa {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -7px;
      color: #999;
    }
  }

  .btn-select-dataset {
    padding: 8px 12px 10px;
    text-align: left;
    .fa {
      position: absolute;
      top: 50%;
      right: 8px;
      margin-top: -7px;
    }
  }

  .date-setting-box {
    display: none;
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
    transition-duration: 0.8s;
    .btn_submit {
      margin-top: 15px;
      padding: 8px 12px 10px;
    }
  }

  .date-setting-head {
    position: relative;
    margin-bottom: 30px;
    .fa {
      position: absolute;
      top: 0;
      right: 0;
      color: #999;
    }
  }

  .modal_section_title .date-setting-head {
    margin-bottom: 0;
    padding-bottom: 15px;
    border-bottom: 1px solid #e0e0e0;
  }

  .dropdown_group {
    width: 100%;
    position: relative;
    .dropdown-menu {
      width: 100%;
    }
  }

  .occupation-setting {
    position: absolute;
    top: 15px;
    right: 10px;
    &.show {
      .occupation-select-modal {
        display: block;
      }
    }
  }

  .occupation-select-modal {
    display: none;
    width: 214px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2000;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #e0e0e0;
    transition-duration: 0.8s;
    .form-group {
      max-height: 300px;
      overflow-y: auto;
    }
    .hrb_rankmodal_filter {
      width: 100%;
      .check_button {
        display: block;
        width: 100%;
        height: 23px;
        text-align: left;
        font-weight: normal;
        line-height: 23px;
        font-size: 15px;
        font-weight: normal;
        color: #666666;
        background: transparent;
        border: none;
        cursor: pointer;
        margin: 12px 0;
        box-sizing: border-box;
        &:focus {
          font-family: 'SpoqaB';
          outline: none;
        }
      }
      .active {
        font-family: 'SpoqaB';
        color: #444444;
      }
    }
  }

  .jp-cycle-group {
    margin-bottom: 15px;
    margin-left: -5px;
    margin-right: -5px;
    .col-xs-4 {
      padding: 0 5px;
    }
  }

  .btn-group-flex {
    display: flex;
    .btn-flex-item {
      flex-grow: 1;
      margin: 0 5px;
    }
  }

  .table_type_0 {
    .rl_col col:first-of-type {
      width: 55px;
    }
    .number {
      width: 40px;
    }
  }

  .table_type_1 {
    .rl_col col:first-of-type {
      width: 55px;
    }
    .number {
      width: 40px;
    }
  }

  .bottom-description {
    text-align: center;
    strong {
      font-family: 'SpoqaB';
      color: $main_green_deep;
    }
    line-height: 2em;
  }

  h3 {
    font-size: 30px;
  }

  /* 작은 기기들 (태블릿, 768px 이상) */

  @media (max-width: 768px) {
    .date-setting {
      float: left;
      position: relative;
      top: auto;
      right: auto;
      margin: 0 15px;
    }
  }

  @media (max-width: 1119px) {
    .jp_title .btn_group {
      position: relative;
    }
  }

  .chart_container {
    border: solid 1px #e0e0e0;
    background: #fff;
    box-sizing: border-box;
  }

  .chart {}

  .chart_wrap {}

  $col_types: ( 1: $main_green_dark, 2: $main_green_deep, 3: $main_green_normal, 4: $main_green_light, 5: #A8D0DB, 6: #7BAFBC, 7: #3C6687, 8: #533C87, 9: #8E7BBC, 10: #B0A8DB, 11: #D1A8DB, 12: #BC7BB0);
  $col_types2: ( 1: $main_green_dark, 2: $main_green_deep, 3: $main_green_normal, 4: $main_green_light, 5: #A8D0DB, 6: #7BAFBC, 7: #3C6687, 8: #533C87, 9: #8E7BBC, 10: #B0A8DB, 11: #D1A8DB, 12: #BC7BB0);
  %colorlist {
    @each $type,
    $color in $col_types {
      &:nth-of-type(12n - #{(12 - $type)}) {
        color: $color;
        .count_content,
        .legend_content {
          color: $color;
        }
      }
    }
  }

  %colorlist2 {
    @each $type,
    $color in $col_types {
      .rlb_tb_row:nth-of-type(#{$type}) {
        .count {
          color: $color;
        }
      }
    }
  }

  /* dounut chart legend */

  .dounut_chart_legend {
    display: table;
    min-height: 220px;
    text-align: center;
    width: 100%;
    .legend_list {
      display: table-row;
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      .col-xs-3,
      .col-xs-6 {
        display: table-cell;
        float: none !important;
        overflow: hidden;
      }
    }
    .legend_item {
      box-sizing: border-box;
      padding-left: 0;
      padding-right: 0;
      margin: 0;
      @extend %colorlist;
    }
    .legend_title {
      text-align: center;
      color: $main_gray_deep;
    }
    .legend_content {
      text-align: center;
    }
    &.md_size {
      min-height: auto;
      .legend_title {
        font-size: 13px;
        padding: 6px 0;
      }
      .legend_content {
        font-size: 25px;
      }
    }
    &.sm_size {
      .legend_title {
        font-size: 13px;
        padding: 6px 0;
      }
      .legend_content {
        font-size: 22px;
      }
    }
  }

  .legend_item {
    vertical-align: middle;
    height: 304px;
    text-align: center;
  }

  .md_dounut {
    .chart_wrap {
      height: 304px;
    }
    .legend_content {
      font-size: 25px;
    }
  }

  .jp_h3_title.border-bottom {
    padding-bottom: 13px;
    margin-bottom: 20px;
  }

  /* tab style - news type */

  .tabs-container {
    padding-top: 39px;
    .tab_type {
      position: absolute;
      top: 0;
      left: 15px;
      z-index: 1;
      &>li.active {
        background-color: transparent !important;
        a,
        &:hover a {
          color: $main_green_deep;
          border: 1px solid #E0E0E0;
          border-bottom-color: transparent;
        }
      }
      .tab_item {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        min-width: 100px;
        padding: 0px 12px;
        margin-right: 7px;
        font-size: 14px;
        line-height: 40px;
        height: 40px;
        color: $main_gray_very_light;
        background-color: #fff;
        border: 1px solid #E0E0E0;
        text-align: center;
        border-radius: 0;
        &.on {
          color: $main_green_deep;
          border-bottom: 1px solid #fff;
        }
        &:hover {
          text-decoration: none;
          background-color: #fff;
          border: 1px solid #E0E0E0;
          color: $main_green_deep;
        }
        &:first-of-type {
          margin-left: 0;
        }
      }
      &>li:hover {
        background-color: transparent !important;
        a {
          color: $main_green_deep;
          background-color: #fff;
          border: 1px solid #E0E0E0;
        }
      }
      &+.ibox-content {
        margin-top: -1px;
      }
    }
  }

  .tab_content {
    display: none;
    &.on {
      display: block;
    }
    &~.alri_tab_content {
      margin-top: 0;
    }
  }

  .horizontal_chart {
    h3 {
      text-align: left;
      margin-top: 0;
    }
  }

  .hr_bar_univ {
    height: 379px;
  }

  .ibox {
    margin-bottom: 0;
    border: none;
  }

  .navbar-default .nav>li.disabled>a:hover,
  .navbar-default .nav>li.disabled>a:focus {
    color: #999;
  }

  .content_guide {
    padding: 0 15px 30px;
    .jp_h3_title {
      padding-bottom: 0;
    }
  }

  .content_guide_text {
    position: relative;
    font-size: 15px;
    line-height: 2;
    letter-spacing: 0.1px;
    color: #666;
    &:after {
      display: block;
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 40px;
      width: 61px;
      height: 44px;
      color: #636363;
      background: url("#{$assets_path}/svg/page-1.svg") 0 0 no-repeat;
      background-size: cover;
    }
  }

  .border-top-bottom,
  .border-left-right {
    border-color: #e0e0e0;
  }

  .jp_h3_title.jp_title_p1 {
    padding-top: 5px;
    padding-bottom: 8px;
  }

  .no_data_layout {
    padding: 42px 0;
    .no_data_img {
      text-align: center;
    }
  }

  .no_data_img {
    margin: -15px;
    padding: 15px 0;
    text-align: center;
  }

  .animate_row>td {
    opacity: 0;
  }

  $total: 15;
  /* to 는 전까지 */

  @for $i from 1 through $total {
    .flip_group .animate_row:nth-child(#{$i}) td {
      -webkit-backface-visibility: visible !important;
      backface-visibility: visible !important;
      -webkit-animation-name: flipInX;
      animation-name: flipInX;
      -webkit-animation-duration: 0.5s * ( $i * 0.3);
      animation-duration: 0.5s * ( $i * 0.3);
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      animation-iteration-count: 2;
      animation-timing-function: ease-in-out;
    }
  }

  @-webkit-keyframes flipInX {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 180deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 180deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
      opacity: 1;
    }
  }

  @keyframes flipInX {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
    to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
      opacity: 1;
    }
  }

  .analysis_jobseeker {
    .no_data_img {
      padding: 58px 0;
      margin: -15px;
      img {
        width: 462px;
      }
    }
  }

  .visit_info {
    .no_data_img {
      padding: 25px 0;
      margin: -15px;
      img {
        max-width: 286px;
      }
    }
  }

  .compare_selected_cate,
  .compare_categories {
    .no_data_img {
      padding: 15px 35px 30px;
      img {
        width: 100%;
      }
    }
  }

  .elipsis_one {
    overflow: hidden;
    word-break: break-all;
    word-wrap: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* jp-modal */

  .jp_modal_container {
    position: relative;
    width: 1000px;
    .modal-header {
      padding: 0 15px;
      border: 0 none;
    }
    .modal-body {
      padding: 0;
    }
    .close {
      position: absolute;
      top: 20px;
      right: 40px;
      padding: 10px;
      font-size: 40px;
      font-weight: 100;
      color: $main_gray_dark;
      z-index: 1;
    }
  }

  .jp_modal_section {
    position: relative;
    .loading_spinner {
      position: absolute;
      top: 0;
      left: 0;
      .loading_spinner_img {
        position: absolute;
      }
      .loading_spinner_bg {
        position: relative;
      }
    }
    .modal_section_title {
      padding: 40px 50px 20px;
      h3 {
        max-width: 89.1%;
        margin: 0;
        font-size: 20px;
        color: #444;
        line-height: 30px;
        word-wrap: break-word;
        word-break: keep-all;
      }
      .em_txt {
        color: $main_green_deep;
      }
    }
    .modal_section_content {
      padding: 30px 50px;
    }
  }

  .loading_spinner.inline_spinner {
    position: absolute;
    top: 0;
    left: 0;
    .loading_spinner_img {
      position: absolute;
    }
    .loading_spinner_bg {
      position: relative;
    }
  }

  .jp_job_unit {
    width: 100%;
    background-color: #fff;
    margin-top: 15px;
    &:first-of-type {
      margin-top: 0;
    }
    &~ :not(.jp_job_unit) {
      margin-top: 20px;
    }
  }

  .job_content_wrap {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 28px 70px 28px 20px;
    border: 1px solid #cecece;
    &:focus,
    &:hover {
      border-color: $main_green_deep;
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 28px;
      display: block;
      content: '';
      width: 13px;
      height: 22px;
      margin-top: -11px;
      background: url('#{$assets_path}/svg/icon-arrow-r.svg') no-repeat 50% 50%;
      background-size: 13px 22px;
    }
  }

  .job_unit_head {
    .job_unit_title {
      display: inline-block;
      max-width: 85.5%;
      color: $main_gray_deep;
      font-size: 18px;
      line-height: 21px;
    }
    .job_jpboosting {
      display: inline-block;
      max-width: 100px;
      font-size: 13px;
      color: $main_gray_light;
      vertical-align: top;
    }
  }

  .job_unit_bottom {
    .badge_group {
      float: left;
      .badge-jp {
        margin-left: 4px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }

  .job_tags_list {
    display: block;
    line-height: 20px;
    .job_tags_item {
      display: inline-block;
      font-size: 13px;
      line-height: 20px;
      color: $main_gray_light;
      vertical-align: top;
      &:before {
        display: inline;
        content: '/ ';
      }
      &:first-of-type {
        &:before {
          display: none;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .jp_modal_container {
      width: auto;
      .close {
        right: 20px;
      }
    }
    .job_content_wrap {
      padding: 28px 35px 28px 20px;
    }
    .jp_modal_section {
      .modal_section_title {
        padding: 40px 25px 20px;
      }
      .modal_section_content {
        padding: 30px 25px;
      }
    }
  }

  .tabs-jp-lg {
    padding-top: 51px;
    .tab_type {
      &>li {
        height: 52px;
      }
      .tab_item {
        height: 52px;
        min-width: 135px;
        max-width: 162px;
        margin-right: 15px;
        padding: 17px 29px 12px;
        font-size: 18px;
        line-height: 20px;
        color: $main_gray_very_light;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }
  }

  .list_jp_news,
  .list_jp_news_more {
    box-sizing: border-box;
    .list_jp_unit:first-of-type {
      margin-top: 20px;
    }
  }

  .list_jp_news {
    max-height: 585px;
  }

  .scroll_y {
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
  }

  .list_jp_unit {
    display: block;
    margin-top: 30px;
    border-bottom: 1px solid #e0e0e0;
    &:first-of-type {
      margin-top: 0;
    }
    .list_unit_content {
      display: block;
      cursor: pointer;
      &:hover,
      &:focus {
        .title_text {
          color: #444;
          text-decoration: underline;
        }
      }
    }
    .title {
      width: 100%;
      max-height: 21px;
      margin-bottom: 15px;
      &:after {
        display: block;
        content: '';
        clear: both;
      }
      .title_text {
        display: inline-block;
        max-width: 74%;
        line-height: 21px;
        font-size: 18px;
        font-weight: 600;
      }
      .info {
        float: right;
        font-size: 13px;
        color: $main_gray_light;
        line-height: 20px;
        vertical-align: top;
        >span {
          display: inline-block;
          vertical-align: top;
        }
      }
      .name {
        max-width: 90px;
      }
      .date {
        margin-left: 15px;
      }
    }
    .content {
      display: block;
      margin-bottom: 15px;
      font-size: 15px;
      color: $main_gray_normal;
      line-height: 30px;
      word-wrap: break-word;
      word-break: break-all;
    }
    a {
      &:hover {
        &.content,
        .content {}
      }
    }
  }

  /* no data */

  .no_data_layout {
    display: none;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #444;
    &.no_data_bg {
      box-sizing: border-box;
      &.bg_pencil {
        min-height: 315px;
        padding: 195px 50px 50px;
        background: #fff url('#{$assets_path}/svg/icon-pencil.svg') no-repeat 50% 75px;
        background-size: 100px 100px;
      }
    }
    .no_data_message {
      font-size: 18px;
      word-break: keep-all;
      word-wrap: break-word;
      text-align: center;
      line-height: 30px;
      .em_txt {
        color: $main_green_deep;
      }
    }
    .no_data_img {
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 101;
      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      >img {
        width: 100%;
        height: auto;
      }
    }
  }

  .is_no_data {
    .no_data_layout {
      display: block;
      &~div {
        display: none;
      }
    }
  }

  /* Salary Filter */

  .filter_result_wrap {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 15px 0;
  }

  .filter_box {
    position: relative;
    padding-right: 150px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e0e0e0;
    box-sizing: border-box;
    .current_category {
      line-height: 34px;
      color: $main_green_deep;
      font-size: 15px;
      min-height: 34px;
    }
    .btn {
      position: absolute;
      top: 0;
      right: 0;
    }
    &~.section_keyword_state {
      margin-top: 40px;
    }
  }

  /* Style for hr_biz_salay */

  .hr_biz_salay {
    .loading_spinner_bg {
      background-color: #f5f5f5;
    }
  }

  /* custom table */

  .table_salary_rank {
    .rl_col col:last-of-type,
    .rlb_tb_row .count {
      width: 75px;
    }
    .out_of_rank {
      height: 335px;
      padding-top: 0;
      text-align: center;
      strong {
        line-height: 2;
      }
    }
    .open_modal {
      .text {
        color: #888;
      }
      &:hover {
        .text {
          color: #666;
        }
      }
    }
    .count {
      font-size: 10px;
    }
    .rlb_tb_row {
      &.active {
        .open_modal {
          .text {
            color: #666;
          }
          &:hover {
            .text {
              color: #444;
            }
          }
        }
      }
    }
  }

  .table_employee {
    @extend %colorlist2;
    margin-top: 30px;
    col:last-child {
      width: 70px;
    }
    td:last-child {
      text-align: right;
      span {
        color: #444;
        width: auto;
        padding: 0;
      }
    }
  }

  /*
    Salary Filter Modal Container,
    Salary Rank Modal Container
  */

  .jp_filter_modal_container,
  .jp_salary_modal_container {
    position: relative;
    width: auto;
    max-width: 1000px;
    &.sm_size {
      max-width: 700px;
    }
    .modal-content {
      box-sizing: border-box;
      width: 100%;
      padding: 50px;
    }
    .modal-header {
      padding: 0;
      border-bottom: 0 none;
      text-align: left;
      .close {
        font-size: 40px;
        font-weight: 100;
        color: $main_gray_dark;
      }
    }
    .modal-body {
      padding: 0;
      background-color: #fff;
    }
    .filter_list_body {
      max-height: 493px;
    }
  }

  /* jp-modal type2 */

  .jp_salary_modal_container {
    .modal-header {
      border-bottom: 1px solid #e0e0e0;
    }
  }

  /* Salary Filter Content */

  .filter_content_wrapper {
    display: flex;
    align-items: stretch;
    &:after {
      display: block;
      content: '';
      clear: both;
    }
    &+.btn_group {
      margin-bottom: 0;
    }
  }

  .filter_list_wrap {
    width: 300px;
    .filter_list_tit {
      padding: 11px 0 15px;
      border-bottom: 1px solid #e0e0e0;
      font-size: 20px;
      color: $main_green_deep;
      text-align: center;
      font-weight: 600;
    }
    .filter_list_body {
      color: $main_gray_normal;
    }
    .form-group {
      padding-top: 10px;
    }
    .filter_item {
      padding: 13px 0 12px 43px;
      label {
        position: relative;
        padding-left: 36px;
        font-weight: normal;
      }
    }
    .icheckbox_square-green {
      position: absolute;
      top: 0;
      left: 0;
      margin-right: 10px;
      background-color: #fff;
    }
  }

  /* Salary Rank Modal */

  .company_salary_box {
    display: table;
    padding-bottom: 30px;
    .box_row {
      display: table-row;
    }
    .box_cell {
      display: table-cell;
      vertical-align: middle;
    }
    .company_logo {
      .jp_admin_llogo {
        margin-right: 20px;
      }
    }
    .company_name {
      width: 230px;
      .name {
        display: inline-block;
        width: inherit;
        font-size: 20px;
      }
      .sub_text {
        display: inline-block;
        width: inherit;
        line-height: 17px;
        color: #666;
      }
    }
    .company_salary {
      min-width: 190px;
      text-align: right;
      font-size: 30px;
      color: #444;
      .won {
        color: #999;
      }
    }
  }

  .jp_salary_modal_section {
    .modal_section_title {
      position: relative;
      padding-top: 30px;
      padding-bottom: 20px;
      h3 {
        max-width: 62%;
        margin: 0;
        font-size: 20px;
        color: #444;
        font-weight: normal;
        line-height: 30px;
        word-wrap: break-word;
        word-break: keep-all;
      }
    }
    .occupation-setting {
      top: 30px;
      right: 0;
    }
    .occupation-set-open {
      background-color: #fafafa;
    }
    .no_data_img {
      overflow: hidden;
      margin: 0;
      &>img {
        width: 100%;
        height: auto;
      }
    }
  }

  /* feed_view_modal */

  .feed_view_modal {
    width: 1000px;
    .modal-content {
      background-color: transparent !important;
    }
    .close {
      font-size: 40px;
      font-weight: 100;
      color: $main_gray_dark;
      position: absolute;
      top: 50px;
      right: 50px;
      z-index: 3;
      cursor: pointer;
    }
    .modal-body {
      padding: 50px;
      background-color: #fff;
    }
    .alri_feed_view {
      width: 900px;
      font-size: 14px;
      &:after {
        content: '';
        display: block;
        clear: both;
      }
      .col_left {
        float: left;
        width: 175px;
      }
      .col_right {
        width: 725px;
        float: left;
        h3 {
          line-height: 30px;
          font-size: 20px;
          text-align: left;
          color: $main_gray_deep;
          &~.text-muted {
            display: block;
            font-size: 13px;
            margin-top: 10px;
            color: $main_gray_light;
            margin-bottom: 20px;
          }
        }
      }
      .feed_content {
        display: block;
        li {
          margin-bottom: 30px;
          h5 {
            height: 30px;
            line-height: 30px;
            font-size: 15px;
            margin-bottom: 5px;
            font-family: 'SpoqaB';
          }
          &>div {
            font-size: 15px;
            line-height: 2em;
          }
          &>em {
            font-family: 'SpoqaB';
          }
        }
      }
      .form-control {
        border-radius: 2px;
        background-color: #fafafa;
        border: solid 1px #e0e0e0;
        resize: none;
        padding: 20px;
      }
      textarea.form-control {
        max-height: 160px;
        line-height: 2em;
        font-size: 15px;
        color: #666;
      }
      .feed-element {
        &>h2 {
          margin: 0 0 50px;
        }
      }
    }
    .btn_group {
      margin-top: 15px;
      margin-bottom: 0;
      input {
        color: #fff;
        border-radius: 2px;
        margin-left: 12px;
      }
    }
  }

  .um_newstar_l {
    position: relative;
    width: 123px;
    height: 23px;
    background: url('https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/star_img_out.png') 0 0 no-repeat;
    background-size: cover;
    overflow: hidden;
    .um_newstar_c {
      position: absolute;
      left: 0;
      height: 100%;
      text-indent: -1000%;
      overflow: hidden;
      background: url('https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/star_img_in.png') 0 0 no-repeat;
    }
  }

  .um_newbar_l {
    position: relative;
    width: 125px;
    height: 5px;
    background: url('https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/bar_img_out.png') 0 0 no-repeat;
    background-size: cover;
    overflow: hidden;
    .um_newbar_c {
      position: absolute;
      left: 0;
      height: 100%;
      text-indent: -1000%;
      overflow: hidden;
      background: url('https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/bar_img_in.png') 0 0 no-repeat;
    }
  }

  .graph_wrap {
    display: block;
    li {
      display: block;
      margin-bottom: 15px;
      .name {
        display: block;
        height: 30px;
        line-height: 30px;
        font-weight: normal;
        font-size: 15px;
        text-align: left;
        color: $main_gray_deep;
        margin: 0 0 5px 0;
      }
      .text {
        line-height: 30px;
        font-weight: normal;
        font-size: 15px;
      }
    }
    .no_graph {
      .name {
        font-family: 'SpoqaB';
        margin-bottom: 0;
      }
    }
    .total {
      margin-bottom: 30px;
    }
  }

  .hrbrankunithead,
  .hrbrankUnit {
    cursor: pointer;
  }

  .modal_dropdown_group {
    position: absolute;
    right: 16px;
    top: 10px;
    width: 200px;
  }

  .description_box {
    white-space: pre-line;
  }

  .modal_section_title {
    .open {
      .occupation-select-modal {
        display: block;
      }
    }
  }

  .jp_salary_modal_section {
    position: relative;
  }

  table.htb_table {
    width: 190px;
    table-layout: fixed;
    .hrb_chart_no {
      font-size: 15px;
      font-weight: bold;
      line-height: 1.67;
      letter-spacing: 0.1px;
      text-align: right;
      color: #444;
      width: 30px;
      padding-right: 10px;
    }
    .hrb_chart_value {
      color: #666;
      width: 190px;
      .hrb_ellipsis {
        display: block;
        width: 100%;
        text-align: left;
        height: 25px;
        line-height: 25px;
        font-size: 15px;
        letter-spacing: 0.1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .hrb_chart_amount {
      font-size: 11px;
      letter-spacing: 0.1px;
      color: #999;
    }
  }

  .modal-middle-content {
    width: 600px;
    padding: 50px;
    box-sizing: content-box;
    margin: 0 auto;
    .modal-body {
      background-color: #fff;
    }
    .close {
      font-size: 40px;
      font-weight: 100;
      color: $main_gray_dark;
      position: absolute;
      top: 50px;
      right: 50px;
      z-index: 3;
      cursor: pointer;
    }
    .btn_group {
      margin-top: 30px;
      text-align: center;
    }
  }

  .sub_madal_content {
    text-align: center;
    font-size: 15px;
    line-height: 2.0;
    text-align: center;
    color: $main_gray_deep;
    strong {
      font-family: 'SpoqaB';
    }
  }

  .highcharts-tooltip>span {
    padding: 12px;
  }

  .hrbtooltip_row {
    padding: 15px;
  }

  .hrbtooltip_table_a {
    margin: 0;
    strong {
      font-family: 'SpoqaB';
    }
    td {
      line-height: 20px;
      height: 20px;
      color: #666666;
      font-family: 'SpoqaR';
      font-size: 13px;
    }
    .hrbsalarycompare_td {
      color: #444;
    }
  }

  .hrb_salarycompare_chart {
    .highcharts-container {
      overflow: visible !important;
    }
    .highcharts-container .highcharts-tooltip span {
      position: static !important;
    }
  }
}
