/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/

// Google Fonts
/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en"); */

// Bootstrap
// @import "bootstrap";
// @import 'bootstrap-datetimepicker';

// Variables, Mixins
@import "./inspinia/base/_variables.scss";
@import "./inspinia/base/_mixins.scss";

// INSPINIA Theme Elements
@import "./inspinia/base/_typography.scss";
@import "./inspinia/base/_navigation.scss";
@import "./inspinia/base/_top_navigation.scss";
@import "./inspinia/base/_buttons.scss";
@import "./inspinia/base/_badges_labels.scss";
@import "./inspinia/base/_elements.scss";
@import "./inspinia/base/_sidebar.scss";
@import "./inspinia/base/_base.scss";
@import "./inspinia/base/_pages.scss";
@import "./inspinia/base/_chat.scss";
@import "./inspinia/base/_metismenu.scss";
@import "./inspinia/base/_spinners.scss";

// Landing page
@import "./inspinia/base/_landing.scss";

// RTL Support
@import "./inspinia/base/_rtl.scss";

// For demo only - config box style
@import "./inspinia/base/_theme-config.scss";

// INSPINIA Skins
@import "./inspinia/base/_skins.scss";
@import "./inspinia/base/_md-skin.scss";

// Media query style
@import "./inspinia/base/_media.scss";

// Custom style
// Your custom style to override base style
@import "./inspinia/base/_custom.scss";

// Font Awesome
/* @import "inspinia/scss/font-awesome"; */
/* @import "font-awesome-sprockets"; */
/* @import "inspinia/scss/font-awesome"; */
/* @import "font-awesome"; */
@import "./font-awesome/font-awesome.min.scss";

// Date Picker
@import "./partners/inspinia/plugins/datapicker/datepicker3.scss";

/* loading bar */
.loading_spinner {
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: none;
  z-index: 100000;
  .loading_spinner_img {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9998;
  }
  .loading_spinner_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .4;
    z-index: 9997;
  }
}
.bouncing-loader {
  display: flex;
  display: -ms-flexbox;
  display: -webkit-flex;

  &__round {
    width: 20px;
    height: 20px;
    background-color: #3C8787;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: 10px;
    }

    animation: bounce 0.6s infinite alternate;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes bounce {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0.1;
    transform: translateY(-20px);
  }
}
