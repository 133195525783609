/* Font */
@font-face {
  font-family: 'SpoqaB';
  src: url('#{$assets_path}/fonts/SpoqaHanSansBold.woff2') format('woff2'),
  url('#{$assets_path}/fonts/SpoqaHanSansBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SpoqaR';
  src: url('#{$assets_path}/fonts/SpoqaHanSansRegular.woff2') format('woff2'),
  url('#{$assets_path}/fonts/SpoqaHanSansRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SpoqaL';
  src: url('#{$assets_path}/fonts/SpoqaHanSansLight.woff2') format('woff2'),
  url('#{$assets_path}/fonts/SpoqaHanSansLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 100;
  src: local('Noto Sans Thin'), local('NotoSans-Thin'),
  url('#{$assets_font_path}/eot/NotoSansKR-Thin-Hestia.eot'),
  url('#{$assets_font_path}/eot/NotoSansKR-Thin-Hestia.eot?#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/NotoSansKR-Thin-Hestia.woff') format('woff'),
  url('#{$assets_font_path}/otf/NotoSansKR-Thin-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans Light'), local('NotoSans-Light'),
  url('#{$assets_font_path}/eot/NotoSansKR-Light-Hestia.eot'),
  url('#{$assets_font_path}/eot/NotoSansKR-Light-Hestia.eot?#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/NotoSansKR-Light-Hestia.woff') format('woff'),
  url('#{$assets_font_path}/otf/NotoSansKR-Light-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 350;
  src: local('Noto Sans DemiLight'), local('NotoSans-DemiLight'),
  url('#{$assets_font_path}/eot/NotoSansKR-DemiLight-Hestia.eot'),
  url('#{$assets_font_path}/eot/NotoSansKR-DemiLight-Hestia.eot?#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/NotoSansKR-DemiLight-Hestia.woff') format('woff'),
  url('#{$assets_font_path}/otf/NotoSansKR-DemiLight-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Regular'), local('NotoSans-Regular'),
  url('#{$assets_font_path}/eot/NotoSansKR-Regular-Hestia.eot'),
  url('#{$assets_font_path}/eot/NotoSansKR-Regular-Hestia.eot?#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/NotoSansKR-Regular-Hestia.woff') format('woff'),
  url('#{$assets_font_path}/otf/NotoSansKR-Regular-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Medium'), local('NotoSans-Medium'),
  url('#{$assets_font_path}/eot/NotoSansKR-Medium-Hestia.eot'),
  url('#{$assets_font_path}/eot/NotoSansKR-Medium-Hestia.eot?#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/NotoSansKR-Medium-Hestia.woff') format('woff'),
  url('#{$assets_font_path}/otf/NotoSansKR-Medium-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
  url('#{$assets_font_path}/eot/NotoSansKR-Bold-Hestia.eot'),
  url('#{$assets_font_path}/eot/NotoSansKR-Bold-Hestia.eot?#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/NotoSansKR-Bold-Hestia.woff') format('woff'),
  url('#{$assets_font_path}/otf/NotoSansKR-Bold-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Noto Sans Korean';
  font-style: normal;
  font-weight: 900;
  src: local('Noto Sans Black'), local('NotoSans-Black'),
  url('#{$assets_font_path}/eot/NotoSansKR-Black-Hestia.eot'),
  url('#{$assets_font_path}/eot/NotoSansKR-Black-Hestia.eot?#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/NotoSansKR-Black-Hestia.woff') format('woff'),
  url('#{$assets_font_path}/otf/NotoSansKR-Black-Hestia.otf') format('opentype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  font-style: normal;
  src: url('#{$assets_font_path}/woff/Roboto-Thin.woff2') format('woff2'),
  url('#{$assets_font_path}/woff/Roboto-Thin.woff') format('woff'),
  url('#{$assets_font_path}/otf/Roboto-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('#{$assets_font_path}/woff/Roboto-Light.woff2') format('woff2'),
  url('#{$assets_font_path}/woff/Roboto-Light.woff') format('woff'),
  url('#{$assets_font_path}/otf/Roboto-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('#{$assets_font_path}/woff/Roboto-Regular.woff2') format('woff2'),
  url('#{$assets_font_path}/woff/Roboto-Regular.woff') format('woff'),
  url('#{$assets_font_path}/otf/Roboto-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('#{$assets_font_path}/woff/Roboto-Medium.woff2') format('woff2'),
  url('#{$assets_font_path}/woff/Roboto-Medium.woff') format('woff'),
  url('#{$assets_font_path}/otf/Roboto-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-style: normal;
  src: url('#{$assets_font_path}/woff/Roboto-Bold.woff2') format('woff2'),
  url('#{$assets_font_path}/woff/Roboto-Bold.woff') format('woff'),
  url('#{$assets_font_path}/otf/Roboto-Bold.otf') format('opentype');
}



.jf_h1{
  font-size: 48px;
  line-height: 72px;
  font-weight: 700;
}

.jf_h2{
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}

.jf_h3{
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}

.jf_h4{
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
}

.jf_h5{
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
}

.jf_h6{
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.jf_h7{
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.jf_h8{
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.jf_h9{
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}

.jf_h10{
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

.jf_h7{
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.jf_h8{
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.jf_h9{
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}

.jf_h10{
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

.jf_b1{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.jf_b2{
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.jf_s1{
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.jf_s2{
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
}

