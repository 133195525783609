/* 190116 jply 모달 버튼 공통 */
.jply_modal_btn {
  display: inline-block;
  margin: 0 8px;
  padding: 14px 24px 16px;
  font-size: 16px;
  border-radius: 3px;
  font-family: 'Roboto', 'Noto Sans Korean', '돋움', dotum, Arial, sans-serif;
  &:hover {
    text-decoration: none;
  }
}
/* 190116 모달 버튼 타입 스타일 */
.jply_btn_ty1 {
  background-color: $green500;
  color: #fff;
  border: 1px solid $green500;
  font-weight: bold;
  &:hover {
    background-color: #00a85d;
    border-color: #00a85d;
  }
}
.jply_btn_ty2 {
  background-color: #fff;
  border: 1px solid $gray200;
  color: $gray400;
  &:hover {
    background-color: $gray50;
  }
}

/* 190116 jply 모달 공통 레이아웃 */
.jply_modal {
  position: relative;
  margin: 80px auto 0;
  box-sizing: border-box;
  width: 600px;
  padding: 30px 56px 56px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  font-family: 'Roboto', 'Noto Sans Korean', '돋움', dotum, Arial, sans-serif;
  z-index: 999;
  .modal_body {
    margin-bottom: 56px;
  }
  .btn_group {
    text-align: center;
  }
  .btn_row {
    text-align: center;
    &::after {
      content:"";
      display: table;
      clear: both;
    }
  }
  .mo_txt_l {
    font-weight: 700;
    font-size: 32px;
    color: $gray800;
  }
  .mo_txt_m {
    font-size: 24px;
    color: $gray400;
    font-weight: bold;
  }
  .mo_txt_s {
    font-size: 15px;
    color: $gray400;
  }
  .btn_close_x_ty1 {
    position: absolute;
    top: 0px;
    right: -47px;
    width: 30px;
    height: 30px;
    background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/ic_close_modal_190108.svg) no-repeat center;
    background-size: cover;
  }
  .modal_simple_btn {
    margin-bottom: 16px;
    text-align: left;
    .btn_prev {
      font-size: 16px;
      color: #85898c;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .jply_modal_btn,
  .jply_ip_label,
  .error_text,
  .jply_simple_tag,
  .mo_txt_s,
  .mo_txt_m {
    font-family: 'Roboto', 'Noto Sans Korean', '돋움', dotum, Arial, sans-serif;
  }
}
/* 190625 jply 모달 공통 레이아웃 추가 */
.jply_temp{
  &.jply_modal {
    padding: 56px;
  }
  .btn_row {
    text-align: center;
    margin: 0 -8px;
    font-size: 0px;
  }
  .jply_modal_btn {
    box-sizing: border-box;
    width: 235px;
  }
  .half_row_btn {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 0 8px;
    .jply_modal_btn {
      box-sizing: border-box;
      width: 100%;
      margin: 0;
    }
  }
  .modal_header {
    margin-bottom: 40px;
  }
  .modal_body {
    margin-bottom: 0;
  }
  .modal_bottom {
    margin-top: 56px;
  }
  .modal_con_tit {
    font-size: 13px;
    line-height: 19px;
    height: 19px;
    margin-bottom: 8px;
    color: $gray400;
    font-weight: bold;
  }
  .modal_con_panel {
    box-sizing: border-box;
    padding: 18px 20px;
    border: 1px solid #e5e6e8;
    border-radius: 3px;
    background: #fff;
    &.link_to {
      display: block;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
      }
    }
  }
  .modal_con_read {
    height: 360px;
    background-color: $gray30;
    overflow-y: scroll;
    color: $gray800;
    border-radius: 3px;
  }
  .con_box {
    box-sizing: border-box;
    padding: 20px 18px;
    background-color: $gray30;
    font-size: 15px;
    line-height: 24px;
  }
  .con_sec {
    margin-bottom: 32px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .con_text {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .con_subtit {
    margin-bottom: 12px;
    color: $gray300;
  }
  .con_text {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 24px;
    color: $gray800;
    font-weight: normal;
  }
  .sub_text {
    font-size: 13px;
    line-height: 18px;
    &.c_orange {
      color: $jply_color_orange;
    }
    &.c_red {
      color: $jply_color_red;
    }
    &.c_green {
      color: $green500;
    }
  }
  .label_state {
    vertical-align: middle;
    margin: 0 0 7px 10px;
    display: inline-block;
    box-sizing: border-box;
    height: 32px;
    padding: 8px 8px 6px;
    font-size: 15px;
    font-weight: normal;
    font-family: 'Roboto', 'Noto Sans Korean', '돋움', dotum, Arial, sans-serif;
    line-height: 17px;
    border-radius: 3px;
    background-color: #f0fff7;
    color: $green500;
    &.red {
      background-color: $red30;
      color: $jply_color_red;
    }
  }
}

/* 20190731 Confirm 모달 공통으로 정의 */
.following_affiliate,
.jply_confirm_modal {
  width: 480px;
  padding: 56px;
  .modal_header {
    height: 120px;
    width: 120px;
    margin: 0 auto 32px;
    background: url(https://jpassets.jobplanet.co.kr/production/uploads/email_medium/media/img_modal_success_190118.svg) no-repeat center;
  }
  .modal_body {
    margin-bottom: 32px;
    text-align: center;
    .mo_txt_l {
      margin-bottom: 0;
      &.mo_txt_inform {
        margin-bottom: 18px;
      }
    }
    .mo_txt_s{
      line-height: 1.6em;
    }
  }
}

/* 모달 공통 스타일 */
.jply_modal_v2 {
  position: relative;
  width: 520px;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0 16px 48px 8px rgba(30, 40, 58, 0.08), 0 6px 48px 0 rgba(30, 40, 58, 0.08), 0 6px 48px 0 rgba(30, 40, 58, 0.08);
  background-color: #fff;
  border: 1px solid $gray100;
  z-index: 30;
  box-sizing: border-box;
  .btn_close_x_ty1 {
    position: absolute;
    top: 12px;
    right: 14px;
    width: 24px;
    height: 25px;
    line-height: 25px;
    font-size: 24px;
    color: $gray500;
  }
  .modal_header {
    display: flex;
  }
  .modal_bottom {
    background-color: #fff;
  }
  .title {
    display: block;
    font-weight: 700;
    text-align: left;
    color: $gray800;
    word-break: break-all;
  }
  .button_group {
    text-align: right;
    [class^="jply_btn"],
    [class*=" jply_btn"] {
      &~[class^="jply_btn"],
      [class*=" jply_btn"] {
        margin-left: 8px;
      }
    }
  }
}
.jply_popup {
  position: fixed !important;
  &.layer_popup_box_on {
    .layer_popup  {
      display: flex;
      height: 100%;
    }
  }
}

/* Basic type 모달 */
.jply_modal_basic_ty {
  align-items: center;
  /* justify-content: center; */ /* ie margin bug */
  .jply_modal_v2 {
    width: 400px;
    padding: 28px;
  }
  .modal_header {
    max-width: 91.27%;
    &~.modal_body {
      margin-top: 12px;
    }
    .jply_icon {
      font-size: 24px;
      line-height: 28px;
      &+.title {
        margin-left: 8px;
      }
    }
  }
  .button_group {
    margin-top: 28px;
  }
  .title {
    font-size: 20px;
    line-height: 28px;
  }
}

/* Contents type 모달 */
.jply_modal_contents_ty {
  align-items: center;
  /* justify-content: center; */ /* ie margin bug */
  overflow-y: scroll;
  .jply_modal_v2 {
    padding: 44px 32px 32px;
  }
  .modal_header {
    &~.modal_body {
      margin-top: 8px;
      &.type-margin{
        margin-top: 32px;
      }
    }
    .jply_icon {
      font-size: 24px;
      line-height: 34px;
      &+.title {
        margin-left: 8px;
      }
    }
  }
  .button_group {
    margin-top: 32px;
  }
  .title {
    font-size: 24px;
    line-height: 34px;
  }
}

/* Scroll type 모달 */
.jply_modal_scroll_ty {
  /* justify-content: center; */ /* ie margin bug */
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 80px 0;
  box-sizing: border-box;
  .jply_modal_v2 {
    display: flex;
    overflow: hidden;
    padding: 44px 0 112px;
    max-height: 600px;
  }
  .modal_contents_wrap {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    min-height: 100%;
    padding: 0 32px;
    flex: 1 1 auto;
  }
  .modal_header {
    &~.modal_body {
      margin-top: 8px;
    }
    .jply_icon {
      font-size: 24px;
      line-height: 34px;
      &+.title {
        margin-left: 8px;
      }
    }
  }
  .button_group {
    margin-top: 32px;
  }
  .title {
    font-size: 24px;
    line-height: 34px;
  }
  .modal_body {
    margin-bottom: 32px;
  }
  .modal_bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0 32px 32px;
    border-top: 1px solid $gray100;
    box-sizing: border-box;
    box-shadow: 0 -10px 10px 0 #fff;
    z-index: 50;
  }
}

/* 모달 기본 너비 사이즈 정의 */
.modal_w_sm {
  width: 400px;
}
.modal_w_md {
  width: 520px;
}
.modal_w_lg {
  width: 680px;
}

/*모달 공통 컬러코드*/
.color-green{
  color:$green500;
}
.modal_graph{
  &__img{
    min-height: 302px;
    img{
      vertical-align: middle;
    }
  }
  .modal_company_name{
    display: inline-block;
    max-width: 260px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: middle;
  }
  &__middle{
    display: inline-block;
    vertical-align: middle;
  }
  .jply_modal_v2{
    width:680px !important;
  }
  .modal_header{
    justify-content: space-between;
    align-items: flex-end;
  }
  .modal_body{
    margin-top:32px !important;
  }
  .modal_sub_text{
    &.type-bottom{
      margin-bottom:8px;
    }
  }
  .modal-overlay{
    position: absolute;
    display: inline-block;
    font-size:18px;
    height: 38px;
    padding:0 12px;
    color:$green500;
    background-color: $green50;
    font-weight:bold;
    vertical-align: middle;
    line-height: 38px;
    border-radius: 4px;
    right: 32px;
  }
  .modal-caption{
    margin-top: 24px;
    font-size: 16px;
    font-weight:bold;
    line-height: 22px;
    color: $gray800;
    text-align: center;
    &__icon{
      position: relative;
      top:-1px;
      color:$gray200;
      font-style: inherit;
      margin-right:8px;
    }
  }
  .button_group{
    margin-top:24px;
  }
}
.modal-empty-graph{
  display: table-cell;
  vertical-align: middle;
  width: 616px;
  height: 272px;
  background-color: $gray30;
  border-radius: 8px;
  text-align: center;
  &__img{
    width: 120px;
    vertical-align: middle;
  }
  &__txt{
    margin-top: 16px;
    font-size: 16px;
    color:$gray400;
  }
}

/* 모달 배경 */
.layer_popup_bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}
.jply_modal_v2 {
  z-index: 9999;
}
.layer_popup_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.modal_bottom {
  &.no-fixed {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    border-radius: 0px;
  }
}
