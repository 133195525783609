@font-face {
  font-family: 'jp-icons-2308';
  src:  url('#{$assets_font_path}/eot/jp-icons-2308.eot?spa2305');
  src:  url('#{$assets_font_path}/eot/jp-icons-2308.eot?spa2305#iefix') format('embedded-opentype'),
  url('#{$assets_font_path}/woff/jp-icons-2308.woff?spa2305') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="jp-"], [class*=" jp-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jp-icons-2308' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jp-quote:before {
  content: "\e900";
}
.jp-star-solid:before {
  content: "\e901";
}
.jp-jobplanet:before {
  content: "\e902";
}
.jp-activity:before {
  content: "\e903";
}
.jp-airplay:before {
  content: "\e904";
}
.jp-alert-circle:before {
  content: "\e905";
}
.jp-alert-octagon:before {
  content: "\e906";
}
.jp-alert-triangle:before {
  content: "\e907";
}
.jp-align-center:before {
  content: "\e908";
}
.jp-align-justify:before {
  content: "\e909";
}
.jp-align-left:before {
  content: "\e90a";
}
.jp-align-right:before {
  content: "\e90b";
}
.jp-anchor:before {
  content: "\e90c";
}
.jp-aperture:before {
  content: "\e90d";
}
.jp-archive:before {
  content: "\e90e";
}
.jp-arrow-down-circle:before {
  content: "\e90f";
}
.jp-arrow-down-left:before {
  content: "\e910";
}
.jp-arrow-down-right:before {
  content: "\e911";
}
.jp-arrow-down:before {
  content: "\e912";
}
.jp-arrow-left-circle:before {
  content: "\e913";
}
.jp-arrow-left:before {
  content: "\e914";
}
.jp-arrow-right-circle:before {
  content: "\e915";
}
.jp-arrow-right:before {
  content: "\e916";
}
.jp-arrow-up-circle:before {
  content: "\e917";
}
.jp-arrow-up-left:before {
  content: "\e918";
}
.jp-arrow-up-right:before {
  content: "\e919";
}
.jp-arrow-up:before {
  content: "\e91a";
}
.jp-at-sign:before {
  content: "\e91b";
}
.jp-award:before {
  content: "\e91c";
}
.jp-bar-chart-2:before {
  content: "\e91d";
}
.jp-bar-chart:before {
  content: "\e91e";
}
.jp-battery-charging:before {
  content: "\e91f";
}
.jp-battery:before {
  content: "\e920";
}
.jp-bell-off:before {
  content: "\e921";
}
.jp-bell:before {
  content: "\e922";
}
.jp-bluetooth:before {
  content: "\e923";
}
.jp-bold:before {
  content: "\e924";
}
.jp-book-open:before {
  content: "\e925";
}
.jp-book:before {
  content: "\e926";
}
.jp-bookmark:before {
  content: "\e927";
}
.jp-box:before {
  content: "\e928";
}
.jp-briefcase:before {
  content: "\e929";
}
.jp-calendar:before {
  content: "\e92a";
}
.jp-camera-off:before {
  content: "\e92b";
}
.jp-camera:before {
  content: "\e92c";
}
.jp-cast:before {
  content: "\e92d";
}
.jp-check-circle:before {
  content: "\e92e";
}
.jp-check-square:before {
  content: "\e92f";
}
.jp-check:before {
  content: "\e930";
}
.jp-chevron-down:before {
  content: "\e931";
}
.jp-chevron-left:before {
  content: "\e932";
}
.jp-chevron-right:before {
  content: "\e933";
}
.jp-chevron-up:before {
  content: "\e934";
}
.jp-chevrons-down:before {
  content: "\e935";
}
.jp-chevrons-left:before {
  content: "\e936";
}
.jp-chevrons-right:before {
  content: "\e937";
}
.jp-chevrons-up:before {
  content: "\e938";
}
.jp-chrome:before {
  content: "\e939";
}
.jp-circle:before {
  content: "\e93a";
}
.jp-clipboard:before {
  content: "\e93b";
}
.jp-clock:before {
  content: "\e93c";
}
.jp-cloud-drizzle:before {
  content: "\e93d";
}
.jp-cloud-lightning:before {
  content: "\e93e";
}
.jp-cloud-off:before {
  content: "\e93f";
}
.jp-cloud-rain:before {
  content: "\e940";
}
.jp-cloud-snow:before {
  content: "\e941";
}
.jp-cloud:before {
  content: "\e942";
}
.jp-code:before {
  content: "\e943";
}
.jp-codepen:before {
  content: "\e944";
}
.jp-codesandbox:before {
  content: "\e945";
}
.jp-coffee:before {
  content: "\e946";
}
.jp-columns:before {
  content: "\e947";
}
.jp-command:before {
  content: "\e948";
}
.jp-compass:before {
  content: "\e949";
}
.jp-copy:before {
  content: "\e94a";
}
.jp-corner-down-left:before {
  content: "\e94b";
}
.jp-corner-down-right:before {
  content: "\e94c";
}
.jp-corner-left-down:before {
  content: "\e94d";
}
.jp-corner-left-up:before {
  content: "\e94e";
}
.jp-corner-right-down:before {
  content: "\e94f";
}
.jp-corner-right-up:before {
  content: "\e950";
}
.jp-corner-up-left:before {
  content: "\e951";
}
.jp-corner-up-right:before {
  content: "\e952";
}
.jp-cpu:before {
  content: "\e953";
}
.jp-credit-card:before {
  content: "\e954";
}
.jp-crop:before {
  content: "\e955";
}
.jp-crosshair:before {
  content: "\e956";
}
.jp-database:before {
  content: "\e957";
}
.jp-delete:before {
  content: "\e958";
}
.jp-disc:before {
  content: "\e959";
}
.jp-dollar-sign:before {
  content: "\e95a";
}
.jp-download-cloud:before {
  content: "\e95b";
}
.jp-download:before {
  content: "\e95c";
}
.jp-droplet:before {
  content: "\e95d";
}
.jp-edit-2:before {
  content: "\e95e";
}
.jp-edit-3:before {
  content: "\e95f";
}
.jp-edit:before {
  content: "\e960";
}
.jp-external-link:before {
  content: "\e961";
}
.jp-eye-off:before {
  content: "\e962";
}
.jp-eye:before {
  content: "\e963";
}
.jp-facebook:before {
  content: "\e964";
}
.jp-fast-forward:before {
  content: "\e965";
}
.jp-feather:before {
  content: "\e966";
}
.jp-figma:before {
  content: "\e967";
}
.jp-file-minus:before {
  content: "\e968";
}
.jp-file-plus:before {
  content: "\e969";
}
.jp-file-text:before {
  content: "\e96a";
}
.jp-file:before {
  content: "\e96b";
}
.jp-film:before {
  content: "\e96c";
}
.jp-filter:before {
  content: "\e96d";
}
.jp-flag:before {
  content: "\e96e";
}
.jp-folder-minus:before {
  content: "\e96f";
}
.jp-folder-plus:before {
  content: "\e970";
}
.jp-folder:before {
  content: "\e971";
}
.jp-framer:before {
  content: "\e972";
}
.jp-frown:before {
  content: "\e973";
}
.jp-gift:before {
  content: "\e974";
}
.jp-git-branch:before {
  content: "\e975";
}
.jp-git-commit:before {
  content: "\e976";
}
.jp-git-merge:before {
  content: "\e977";
}
.jp-git-pull-request:before {
  content: "\e978";
}
.jp-github:before {
  content: "\e979";
}
.jp-gitlab:before {
  content: "\e97a";
}
.jp-globe:before {
  content: "\e97b";
}
.jp-grid:before {
  content: "\e97c";
}
.jp-hard-drive:before {
  content: "\e97d";
}
.jp-hash:before {
  content: "\e97e";
}
.jp-headphones:before {
  content: "\e97f";
}
.jp-heart:before {
  content: "\e980";
}
.jp-help-circle:before {
  content: "\e981";
}
.jp-hexagon:before {
  content: "\e982";
}
.jp-home:before {
  content: "\e983";
}
.jp-image:before {
  content: "\e984";
}
.jp-inbox:before {
  content: "\e985";
}
.jp-info:before {
  content: "\e986";
}
.jp-instagram:before {
  content: "\e987";
}
.jp-italic:before {
  content: "\e988";
}
.jp-key:before {
  content: "\e989";
}
.jp-layers:before {
  content: "\e98a";
}
.jp-layout:before {
  content: "\e98b";
}
.jp-life-buoy:before {
  content: "\e98c";
}
.jp-link-2:before {
  content: "\e98d";
}
.jp-link:before {
  content: "\e98e";
}
.jp-linkedin:before {
  content: "\e98f";
}
.jp-list:before {
  content: "\e990";
}
.jp-loader:before {
  content: "\e991";
}
.jp-lock:before {
  content: "\e992";
}
.jp-log-in:before {
  content: "\e993";
}
.jp-log-out:before {
  content: "\e994";
}
.jp-mail:before {
  content: "\e995";
}
.jp-map-pin:before {
  content: "\e996";
}
.jp-map:before {
  content: "\e997";
}
.jp-maximize-2:before {
  content: "\e998";
}
.jp-maximize:before {
  content: "\e999";
}
.jp-meh:before {
  content: "\e99a";
}
.jp-menu:before {
  content: "\e99b";
}
.jp-message-circle:before {
  content: "\e99c";
}
.jp-message-square:before {
  content: "\e99d";
}
.jp-mic-off:before {
  content: "\e99e";
}
.jp-mic:before {
  content: "\e99f";
}
.jp-minimize-2:before {
  content: "\e9a0";
}
.jp-minimize:before {
  content: "\e9a1";
}
.jp-minus-circle:before {
  content: "\e9a2";
}
.jp-minus-square:before {
  content: "\e9a3";
}
.jp-minus:before {
  content: "\e9a4";
}
.jp-monitor:before {
  content: "\e9a5";
}
.jp-moon:before {
  content: "\e9a6";
}
.jp-more-horizontal:before {
  content: "\e9a7";
}
.jp-more-vertical:before {
  content: "\e9a8";
}
.jp-mouse-pointer:before {
  content: "\e9a9";
}
.jp-move:before {
  content: "\e9aa";
}
.jp-music:before {
  content: "\e9ab";
}
.jp-navigation-2:before {
  content: "\e9ac";
}
.jp-navigation:before {
  content: "\e9ad";
}
.jp-octagon:before {
  content: "\e9ae";
}
.jp-package:before {
  content: "\e9af";
}
.jp-paperclip:before {
  content: "\e9b0";
}
.jp-pause-circle:before {
  content: "\e9b1";
}
.jp-pause:before {
  content: "\e9b2";
}
.jp-pen-tool:before {
  content: "\e9b3";
}
.jp-percent:before {
  content: "\e9b4";
}
.jp-phone-call:before {
  content: "\e9b5";
}
.jp-phone-forwarded:before {
  content: "\e9b6";
}
.jp-phone-incoming:before {
  content: "\e9b7";
}
.jp-phone-missed:before {
  content: "\e9b8";
}
.jp-phone-off:before {
  content: "\e9b9";
}
.jp-phone-outgoing:before {
  content: "\e9ba";
}
.jp-phone:before {
  content: "\e9bb";
}
.jp-pie-chart:before {
  content: "\e9bc";
}
.jp-play-circle:before {
  content: "\e9bd";
}
.jp-play:before {
  content: "\e9be";
}
.jp-plus-circle:before {
  content: "\e9bf";
}
.jp-plus-square:before {
  content: "\e9c0";
}
.jp-plus:before {
  content: "\e9c1";
}
.jp-pocket:before {
  content: "\e9c2";
}
.jp-power:before {
  content: "\e9c3";
}
.jp-printer:before {
  content: "\e9c4";
}
.jp-radio:before {
  content: "\e9c5";
}
.jp-refresh-ccw:before {
  content: "\e9c6";
}
.jp-refresh-cw:before {
  content: "\e9c7";
}
.jp-repeat:before {
  content: "\e9c8";
}
.jp-rewind:before {
  content: "\e9c9";
}
.jp-rotate-ccw:before {
  content: "\e9ca";
}
.jp-rotate-cw:before {
  content: "\e9cb";
}
.jp-rss:before {
  content: "\e9cc";
}
.jp-save:before {
  content: "\e9cd";
}
.jp-scissors:before {
  content: "\e9ce";
}
.jp-search:before {
  content: "\e9cf";
}
.jp-send:before {
  content: "\e9d0";
}
.jp-server:before {
  content: "\e9d1";
}
.jp-settings:before {
  content: "\e9d2";
}
.jp-share-2:before {
  content: "\e9d3";
}
.jp-share:before {
  content: "\e9d4";
}
.jp-shield-off:before {
  content: "\e9d5";
}
.jp-shield:before {
  content: "\e9d6";
}
.jp-shopping-bag:before {
  content: "\e9d7";
}
.jp-shopping-cart:before {
  content: "\e9d8";
}
.jp-shuffle:before {
  content: "\e9d9";
}
.jp-sidebar:before {
  content: "\e9da";
}
.jp-skip-back:before {
  content: "\e9db";
}
.jp-skip-forward:before {
  content: "\e9dc";
}
.jp-slack:before {
  content: "\e9dd";
}
.jp-slash:before {
  content: "\e9de";
}
.jp-sliders:before {
  content: "\e9df";
}
.jp-smartphone:before {
  content: "\e9e0";
}
.jp-smile:before {
  content: "\e9e1";
}
.jp-speaker:before {
  content: "\e9e2";
}
.jp-square:before {
  content: "\e9e3";
}
.jp-star:before {
  content: "\e9e4";
}
.jp-stop-circle:before {
  content: "\e9e5";
}
.jp-sun:before {
  content: "\e9e6";
}
.jp-sunrise:before {
  content: "\e9e7";
}
.jp-sunset:before {
  content: "\e9e8";
}
.jp-tablet:before {
  content: "\e9e9";
}
.jp-tag:before {
  content: "\e9ea";
}
.jp-target:before {
  content: "\e9eb";
}
.jp-terminal:before {
  content: "\e9ec";
}
.jp-thermometer:before {
  content: "\e9ed";
}
.jp-thumbs-down:before {
  content: "\e9ee";
}
.jp-thumbs-up:before {
  content: "\e9ef";
}
.jp-toggle-left:before {
  content: "\e9f0";
}
.jp-toggle-right:before {
  content: "\e9f1";
}
.jp-trash-2:before {
  content: "\e9f2";
}
.jp-trash:before {
  content: "\e9f3";
}
.jp-trello:before {
  content: "\e9f4";
}
.jp-trending-down:before {
  content: "\e9f5";
}
.jp-trending-up:before {
  content: "\e9f6";
}
.jp-triangle:before {
  content: "\e9f7";
}
.jp-truck:before {
  content: "\e9f8";
}
.jp-tv:before {
  content: "\e9f9";
}
.jp-twitter:before {
  content: "\e9fa";
}
.jp-type:before {
  content: "\e9fb";
}
.jp-umbrella:before {
  content: "\e9fc";
}
.jp-underline:before {
  content: "\e9fd";
}
.jp-unlock:before {
  content: "\e9fe";
}
.jp-upload-cloud:before {
  content: "\e9ff";
}
.jp-upload:before {
  content: "\ea00";
}
.jp-user-check:before {
  content: "\ea01";
}
.jp-user-minus:before {
  content: "\ea02";
}
.jp-user-plus:before {
  content: "\ea03";
}
.jp-user-x:before {
  content: "\ea04";
}
.jp-user:before {
  content: "\ea05";
}
.jp-users:before {
  content: "\ea06";
}
.jp-video-off:before {
  content: "\ea07";
}
.jp-video:before {
  content: "\ea08";
}
.jp-voicemail:before {
  content: "\ea09";
}
.jp-volume-1:before {
  content: "\ea0a";
}
.jp-volume-2:before {
  content: "\ea0b";
}
.jp-volume-x:before {
  content: "\ea0c";
}
.jp-volume:before {
  content: "\ea0d";
}
.jp-watch:before {
  content: "\ea0e";
}
.jp-wifi-off:before {
  content: "\ea0f";
}
.jp-wifi:before {
  content: "\ea10";
}
.jp-wind:before {
  content: "\ea11";
}
.jp-x-circle:before {
  content: "\ea12";
}
.jp-x-octagon:before {
  content: "\ea13";
}
.jp-x-square:before {
  content: "\ea14";
}
.jp-x:before {
  content: "\ea15";
}
.jp-youtube:before {
  content: "\ea16";
}
.jp-zap-off:before {
  content: "\ea17";
}
.jp-zap:before {
  content: "\ea18";
}
.jp-zoom-in:before {
  content: "\ea19";
}
.jp-zoom-out:before {
  content: "\ea1a";
}
.jp-badge-new:before {
  content: "\ea1b";
}
.jp-checkbox-checked:before {
  content: "\ea1c";
}
.jp-checkbox-unchecked:before {
  content: "\ea1d";
}
.jp-radio-checked:before {
  content: "\ea1e";
}
.jp-radio-unchecked:before {
  content: "\ea1f";
}
.jp-chevron-left-thin:before {
  content: "\ea20";
}
.jp-chevron-right-thin:before {
  content: "\ea21";
}
.jp-close-thin:before {
  content: "\ea22";
}
.jp-menu-grid:before {
  content: "\ea23";
}
.jp-flag-solid:before {
  content: "\ea24";
}
.jp-bookmark-solid:before {
  content: "\ea25";
}
.jp-heart-solid:before {
  content: "\ea26";
}
.jp-lock-solid:before {
  content: "\ea27";
}
.jp-unlock-solid:before {
  content: "\ea28";
}
.jp-thumbs-up-solid:before {
  content: "\ea29";
}
.jp-thumbs-down-solid:before {
  content: "\ea2a";
}
.jp-toggle-left-solid:before {
  content: "\ea2b";
}
.jp-toggle-right-solid:before {
  content: "\ea2c";
}
.jp-jp-company:before {
  content: "\ea2d";
}
.jp-briefcase2:before {
  content: "\ea2e";
}
.jp-briefcase2_fill:before {
  content: "\ea2f";
}
.jp-home_fill:before {
  content: "\ea30";
}
.jp-interview:before {
  content: "\ea31";
}
.jp-news_fill:before {
  content: "\ea32";
}
.jp-news:before {
  content: "\ea33";
}
.jp-search_fill:before {
  content: "\ea34";
}
.jp-user_fill:before {
  content: "\ea35";
}
.jp-won-sign:before {
  content: "\ea36";
}
.jp-logo_facebook:before {
  content: "\ea37";
}
.jp-logo_kakao:before {
  content: "\ea38";
}
.jp-logo_linkedin:before {
  content: "\ea39";
}
.jp-logo_naver:before {
  content: "\ea3a";
}
.jp-logo_twitter:before {
  content: "\ea3b";
}
.jp-alert-circle_fill:before {
  content: "\ea3c";
}
.jp-alert-triangle_fill:before {
  content: "\ea3d";
}
.jp-bell_fill:before {
  content: "\ea3e";
}
.jp-bell-off_fill:before {
  content: "\ea3f";
}
.jp-checkbox-checked_fill:before {
  content: "\ea40";
}
.jp-filter_fill:before {
  content: "\ea41";
}
.jp-x-circle_fill:before {
  content: "\ea42";
}
.jp-info_fill:before {
  content: "\ea43";
}
.jp-help-circle_fill:before {
  content: "\ea44";
}
.jp-vertical:before {
  content: "\ea45";
}
.jp-horizon:before {
  content: "\ea46";
}
.jp-checkbox-multi-checked:before {
  content: "\ea47";
}
.jp-plus-circle_fill:before {
  content: "\ea48";
}
.jp-minus-circle_fill:before {
  content: "\ea49";
}